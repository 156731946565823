import UniqTokenJson from "abis/UniqToken.json";
import UniqNFT from "abis/UniqNFT.json";
import UniqNFT1155 from "abis/UniqNFT1155.json";
import UniqCollectionsJson from "abis/UniqCollections.json";
import UniqValentines from "abis/UniqValentines.json";
import UniqGeneratorJson from "abis/UniqGenerator.json";
import UniqGenesisJson from "abis/UniqGenesis.json";
import UniqPresaleJson from "abis/UniqPresale.json";
import UniqRedeemJson from "abis/UniqRedeem.json";
import UniqVestingJson from "abis/UniqVesting.json";
import UniqVestingPrivateJson from "abis/UniqVestingPrivate.json";
import UniqSantaJson from "abis/UniqSanta.json";
import UniqSLPJson from "abis/UniqSLP.json";
import TokenMockJson from "abis/TokenMock.json";
import UniqPaymentProxyV3Json from "abis/UniqPaymentProxyV3.json";
import UniqStorePaymentJson from "abis/UniqStorePayment.json";
import UniqRedeemV3BurnableJson from "abis/UniqRedeemV3Burnable.json";
import { ContractMap } from "types/contracts";

export const CONTRACTS: ContractMap = {
  TOKEN: UniqTokenJson,
  GENERATOR: UniqGeneratorJson,
  VESTING_PRIVATE: UniqVestingPrivateJson,
  VESTING: UniqVestingJson,
  REDEEM: UniqRedeemJson,
  REDEEM_V3_BURNABLE: UniqRedeemV3BurnableJson,
  PRESALE: UniqPresaleJson,
  GENESIS: UniqGenesisJson,
  UNIQ_NFT: UniqNFT,
  UNIQ_NFT_1155: UniqNFT1155,
  VALENTINES_COLLECTIONS: UniqValentines,
  UNIQ_SANTA: UniqSantaJson,
  UNIQ_SLP: UniqSLPJson,
  LP_TOKEN: TokenMockJson,
  PAYMENT_PROXY_V3: UniqPaymentProxyV3Json,
  COLLECTIONS: UniqCollectionsJson,
  STORE_PAYMENT: UniqStorePaymentJson,
};
