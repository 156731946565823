export enum PAYMENT_TOKENS {
  ETH = "ETH",
  WETH = "WETH",
  UNIQ = "UNIQ",
  BANANA = "BANANA",
  HYVE = "HYVE",
  USDT = "USDT",
  USDC = "USDC",
  PEACE = "PEACE",
  BUBBLE = "BUBBLE",
  ARCADA = "ARCADA",
  APE = "APE",
  MANA = "MANA",
  VOLT = "VOLT",
  EXP = "EXP",
  DAI = "DAI",
  ALL = "ALL",
}

export enum BSC_TOKENS {
  BNB = "BNB",
  SUB = "SUB",
  HOOP = "HOOP",
}

export enum POLYGON_TOKENS {
  MATIC = "MATIC",
  UNIQ_POLYGON = "UNIQ_POLYGON",
  WETH_POLYGON = "WETH_POLYGON",
  HYVE_POLYGON = "HYVE_POLYGON",
  BULL_POLYGON = "BULL_POLYGON",
  APE_POLYGON = "APE_POLYGON",
  VOLT_POLYGON = "VOLT_POLYGON",
}

export enum PAYMENT_FIATS {
  USD = "USD",
  PLN = "PLN",
}

export type ALL_TOKENS =
  | PAYMENT_TOKENS
  | POLYGON_TOKENS
  | BSC_TOKENS
  | PAYMENT_FIATS;

export type ALL_CRYPTO_TOKENS = PAYMENT_TOKENS | POLYGON_TOKENS | BSC_TOKENS;
