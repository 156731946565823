import { Theme, alpha } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const cookieBoxStyles = {
  cookies: (theme: Theme): SystemStyleObject<Theme> => ({
    ".CookieConsent > div:nth-of-type(1)": {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
      display: "flex",
      justifyContent: "center",
      paddingLeft: "20vw",
      "@media (max-width: 1600px)": {
        paddingLeft: "12.5vw",
      },
      "@media (max-width: 1350px)": {
        paddingLeft: "7vw",
      },
      "@media (max-width: 1000px)": {
        paddingLeft: "0",
      },
      "& div": {
        border: "5px solid red",
      },
    },
    ".CookieConsent > div:nth-of-type(2)": {
      width: "27.5vw",
      marginTop: "auto",
      marginBottom: "auto",
      "@media (max-width: 1500px)": {
        width: "22.5vw",
      },
      "@media (max-width: 500px)": {
        display: "flex",
        justifyContent: "center",
        width: "100vw",
      },
    },
    ".CookieConsent > div:nth-of-type(2) > button": {
      backgroundColor: `${theme.palette.text.primary} !important`,
      color: theme.palette.background.default + "!important",
      textTransform: "uppercase",
      fontSize: 14,
      fontWeight: 600,
      padding: "12px 16px !important",
      borderRadius: "0px !important",
      transition: theme.transitions.create(["box-shadow", "background-color"]),
      "&:hover,&:focus": {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.text.primary,
        boxShadow: `0 14px 26px -12px ${alpha(
          theme.palette.text.primary,
          0.42,
        )}, 0 4px 23px 0px ${alpha(
          theme.palette.text.primary,
          0.12,
        )}, 0 8px 10px -5px ${alpha(
          theme.palette.text.primary,
          0.2,
        )} !important`,
      },
      "&:active": {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }),
};

export default cookieBoxStyles;
