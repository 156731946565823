import {
  createTheme,
  SimplePaletteColorOptions,
  Theme,
  ThemeOptions,
} from "@mui/material/styles";

import { THEME_MODES } from "enums/themeModes";

//ToDo temporary
declare module "@mui/material/styles" {
  interface Palette {
    gray?: Palette["primary"];
    headerBgColor?: SimplePaletteColorOptions;
    headerBgImage?: SimplePaletteColorOptions;
    headerMenuButtonColor?: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    gray?: PaletteOptions["primary"];
    headerBgColor?: SimplePaletteColorOptions;
    headerBgImage?: SimplePaletteColorOptions;
    headerMenuButtonColor?: SimplePaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    gray?: true;
    transparent?: true;
    white?: true;
  }
}

export const boxShadowBlack = {
  boxShadow:
    "0 4px 18px 0px rgba(0,0,0, 0.12), 0 7px 10px -5px rgba(0,0,0, 0.15)",
};

export const themeOptions = (mode: THEME_MODES): ThemeOptions => ({
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
        fixed: true,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
        },
        indicator: {
          display: "none",
        },
        flexContainer: {
          flexWrap: "wrap",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#FFF",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 42,
        },
        multiline: {
          height: "auto",
          padding: 0,
        },
        inputSizeSmall: {
          padding: "13.5px 14px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#FFAB49",
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        leaveTouchDelay: 20000,
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          "& > svg": {
            color:
              mode === THEME_MODES.DARK
                ? "rgba(255, 255, 255, 0.58)"
                : "rgba(0, 0, 0, 0.38)",
          },
          "& > svg.Mui-active": {
            color: "#FFAB49",
          },
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          "@media (max-width: 900px)": {
            marginLeft: 0,
            paddingLeft: 0,
            paddingRight: 0,
            borderLeft: "none",
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          "@media (max-width: 900px)": {
            display: "none",
          },
        },
      },
    },
  },
  palette: {
    ...(mode === THEME_MODES.DARK
      ? {
          background: {
            default: "#181818",
            paper: "#2A2A2A",
          },
          primary: {
            dark: "#ECA843",
            main: "#FFF",
            light: "#AD7E38",
          },
          secondary: {
            light: "#F6C882",
            main: "#FFAB49",
            dark: "#AD7E38",
          },
          warning: {
            main: "#F19164",
            light: "#ff9800",
          },
          danger: {
            main: "#D44333",
          },
          success: {
            main: "#3AA76D",
          },
          error: {
            main: "#D44333",
          },
          info: {
            300: "#D8E8FB",
            500: "#2F86fB",
            600: "#515187",
            900: "#2B2B48",
            main: "#455FF3",
            light: "#8CE3FF",
            dark: "#3952F5",
          },
          grey: {
            300: "#d5d5da",
            500: "#BEBEBE",
            600: "#737373",
            700: "#505050",
            800: "#232323",
            900: "#0E0E0E",
          },
          text: {
            primary: "#F6F6F6",
            secondary: "#181818",
          },
          divider: "#ffffff87",
        }
      : {
          background: {
            default: "#FFF",
            paper: "#DDD",
          },
          primary: {
            main: "#000",
            light: "#F4F7FF",
          },
          secondary: {
            light: "#F6C882",
            main: "#ECA843",
            dark: "#AD7E38",
          },
          warning: {
            main: "#F19164",
          },
          danger: {
            main: "#D44333",
          },
          success: {
            main: "#3AA76D",
          },
          error: {
            main: "#D44333",
          },
          info: {
            300: "#D8E8FB",
            500: "#2F86fB",
            light: "#8CE3FF",
          },
          grey: {
            300: "#55555a",
            500: "#414141",
            800: "#FFFFFF",
          },
          text: {
            primary: "#000",
            secondary: "#FFF",
          },
        }),
  },
  typography: {
    allVariants: {
      fontFamily: "inherit",
    },
    h1: {
      fontSize: 48,
      fontWeight: 500,
      lineHeight: "60px",
      "@media (max-width: 600px)": {
        fontSize: 30,
        lineHeight: "40px",
      },
    },
    h2: {
      fontSize: 30,
      fontWeight: 500,
      "@media (max-width: 600px)": {
        textAlign: "center",
        fontSize: 24,
      },
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
      "@media (max-width: 600px)": {
        fontSize: 18,
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
      "@media (max-width: 600px)": {
        fontSize: 14,
      },
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.6,
    },
    subtitle2: {
      fontSize: 10,
      fontWeight: 300,
    },
  },
  shape: {
    borderRadius: 0,
  },
});

const theme = (
  mode: typeof THEME_MODES.DARK | typeof THEME_MODES.LIGHT,
): Theme => createTheme(themeOptions(mode));

export default theme;
