import { FC, ReactNode } from "react";

import Box, { BoxProps } from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Breakpoint } from "@mui/system/createTheme/createBreakpoints";
import { useTheme } from "@mui/material/styles";

import styles from "./styles";

type DividerProps = {
  children?: ReactNode;
  show?: boolean;
  my?: number;
  hideOnBreakPoint?: number | Breakpoint;
  useBreakPoint?: boolean;
  dark?: boolean;
  defaultColor?: boolean;
  isVertical?: boolean;
  height?: number;
};

const Divider: FC<DividerProps & BoxProps> = ({
  children,
  show = true,
  my = 2,
  hideOnBreakPoint = "md",
  useBreakPoint = false,
  dark = false,
  isVertical = false,
  defaultColor = false,
  height = 1,
  ...props
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(hideOnBreakPoint));

  const dividerStyles = [
    isVertical
      ? styles.verticalDivider(defaultColor, height)
      : styles.horizontalDivider(defaultColor, height),
    dark && styles.dark,
  ];

  if (useBreakPoint) {
    return (
      <>
        <Box
          my={!matches ? my : undefined}
          {...props}
          sx={[
            matches ? {} : dividerStyles,
            ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
          ]}
        />
        {children}
      </>
    );
  }

  return show ? (
    <>
      <Box
        my={my}
        {...props}
        sx={[
          ...dividerStyles,
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
      {children}
    </>
  ) : null;
};

export default Divider;
