import fromPairs from "lodash/fromPairs";
import toPairs from "lodash/toPairs";
import isEqual from "lodash/isEqual";
import differenceWith from "lodash/differenceWith";

import { NestedObject } from "types/helpers";

export const generateNonce = () => Math.random().toString(36).substr(2);

export const isEmail = (email: string) => email && email.indexOf("@") !== -1;

export const paginateArray = <T>(array: T[], pagination) => {
  const total = array.length;
  const from = (pagination.page - 1) * pagination.limit;
  const to = from + pagination.limit > total ? total : from + pagination.limit;
  return array.slice(from, to);
};

export const deepDiff = (a: NestedObject, b: NestedObject): NestedObject =>
  fromPairs(differenceWith(toPairs(a), toPairs(b), isEqual));
