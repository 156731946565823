import { withScope } from "@sentry/nextjs";

import { API_ROUTES } from "config/routes";

import { captureException } from "utils/errors";

class CustomError extends Error {
  response: unknown;
  data: unknown;

  constructor({ message, response, data }) {
    super(message);
    this.response = response;
    this.data = data;
  }
}

// TODO: Remove any when everywhing will be typed
const fetcher = async <T = any>(
  url: string,
  payload?: BodyInit | null | object,
  method = "GET",
  headers: HeadersInit = {
    "Content-Type": "application/json",
  },
  credentials: RequestCredentials | null = "include",
) => {
  const options = {
    method,
    ...(method !== "GET" && payload && { body: JSON.stringify(payload) }),
    ...(headers && { headers }),
    ...(credentials && { credentials }),
  };

  try {
    const response = await fetch(url, options);

    // console transfer response
    if (url === API_ROUTES.NFTS_TRANSFER && !response.ok) {
      captureException(`Transfer error: ${JSON.stringify(response)}`);
    }

    const data = (await response.json()) as T & { error?: string };

    if (response.ok) {
      return data;
    }

    const error = new CustomError({
      message: data.error || response.statusText || "Error",
      response,
      data,
    });

    throw error;
  } catch (error) {
    withScope((scope) => {
      scope.setExtra("url", url);
      scope.setExtra("options", JSON.stringify(options));
      captureException(
        `[Fetch]: ${
          error?.data ? JSON.stringify(error.data) : JSON.stringify(error)
        }`,
      );
    });

    if (!error.data) {
      error.data = { message: error.message };
    }
    throw error;
  }
};

export default fetcher;
