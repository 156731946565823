import { useRouter } from "next/router";

import { FC } from "react";
import { CookieConsent } from "react-cookie-consent";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import CustomLink from "components/CustomLink";

import { APP_ROUTES } from "config/routes";

import styles from "./styles";

const CookiesBox: FC = () => {
  const theme = useTheme();

  const router = useRouter();

  const isCookieBoxHidden =
    router.asPath.includes(APP_ROUTES.ITEMS) ||
    router.asPath.includes(APP_ROUTES.ITEMS_NFT) ||
    router.asPath.includes(APP_ROUTES.DISPENSER);

  if (isCookieBoxHidden) {
    return null;
  }

  return (
    <Box sx={styles.cookies}>
      <CookieConsent
        style={{ background: theme.palette.background.default }}
        cookieName="Uniqly.io"
      >
        <Typography variant="body2">
          We use cookies to make your interactions with our website more
          meaningful. They help us better understand how our websites are used,
          so we can tailor content for you. For more information about the
          different cookies we are using, read the{" "}
          <CustomLink href="privacy" target="_blank">
            Privacy Policy
          </CustomLink>
          .
        </Typography>
      </CookieConsent>
    </Box>
  );
};

export default CookiesBox;
