import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const modalStyles = {
  modalRoot: (): SystemStyleObject<Theme> => ({
    overflow: "auto",
    display: "block",
    zIndex: "9999",
  }),
  modal: (theme: Theme): SystemStyleObject<Theme> => ({
    borderRadius: 0,
    overflow: "hidden",
    border: `1px solid ${theme.palette.secondary.main}`,
  }),
  darkBackgroundModal: (): SystemStyleObject<Theme> => ({
    overflow: "auto",
    display: "block",
    zIndex: "99999",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  }),
  modalWidth: (): SystemStyleObject<Theme> => ({
    maxWidth: "none",
    margin: 1,
  }),
  modalCloseButton: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.text.primary,
    },
    cursor: "pointer",
    paddingTop: 2,
    paddingRight: 2,
    paddingBottom: 1,
  }),
  modalBody: (theme: Theme): SystemStyleObject<Theme> => ({
    paddingTop: 0,
    paddingRight: 4,
    paddingBottom: 5,
    paddingLeft: 4,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 2,
      paddingLeft: 2,
    },
  }),
  modalDisabledCloseButton: (): SystemStyleObject<Theme> => ({
    paddingTop: "40px",
  }),
};

export default modalStyles;
