import Router from "next/router";

import { GA4React } from "ga-4-react";

let ga4react;

export const init = async (G) => {
  if (!GA4React.isInitialized() && G && process.browser) {
    ga4react = new GA4React(G, { debug_mode: !process.env.production });

    try {
      await ga4react.initialize();
      logPageViews();
    } catch (err) {
      console.info(err);
    }
  }
};

const logPageView = () => {
  window && logPageViewWithPath(window.location.pathname);
};

const logPageViewWithPath = (path) => {
  window && ga4react.pageview(path);
};

const logPageViews = () => {
  logPageView();

  Router.events.on("routeChangeComplete", () => {
    logPageView();
  });
};

export const logGAEvent = (event, value = null) => {
  try {
    ga4react.event(event?.action, event?.label, event?.category, value);
    /* eslint-disable-next-line */
  } catch {}
};
