import { v4 as uuidV4 } from "uuid";

import {
  ShoppingCart as ShoppingCartInterface,
  ShoppingCartItem,
} from "modules/Cart/ShoppingCart.interface";

import { CART_ITEM_TYPES } from "enums/cartItemTypes";

import { getObjectItem, setObjectItem, removeItem } from "utils/storage";

class ShoppingCart implements ShoppingCartInterface {
  private readonly cartStorageKey = "basket";

  add(item: Omit<ShoppingCartItem, "cartId">) {
    const data = getObjectItem<ShoppingCartItem[]>(this.cartStorageKey);
    if (!data) {
      setObjectItem(this.cartStorageKey, [{ cartId: uuidV4(), ...item }]);
      return;
    }

    if (item.type === CART_ITEM_TYPES.MINT) {
      const itemExist = data.find(
        (cartItem) => item.bundleId === cartItem.bundleId,
      );
      if (!itemExist) {
        setObjectItem(this.cartStorageKey, [
          ...data,
          { cartId: uuidV4(), ...item },
        ]);
        return;
      }

      setObjectItem(
        this.cartStorageKey,
        data.map((cartItem) => {
          if (item.bundleId === cartItem.bundleId && cartItem.amount < 40) {
            return { ...cartItem, amount: cartItem.amount + 1 };
          }
          return cartItem;
        }),
      );
    }

    if (item.type === CART_ITEM_TYPES.REDEEM) {
      const itemExist = data.find(
        (cartItem) =>
          item.contractAddress === cartItem.contractAddress &&
          item.tokenId === cartItem.tokenId,
      );
      if (!itemExist) {
        setObjectItem(this.cartStorageKey, [
          ...data,
          { cartId: uuidV4(), ...item },
        ]);
        return;
      }

      return;
    }
  }

  remove(itemsIds: ShoppingCartItem["cartId"][]) {
    const data = getObjectItem<ShoppingCartItem[]>(this.cartStorageKey);
    if (!data) return;

    setObjectItem(
      this.cartStorageKey,
      data.filter((item) => !itemsIds.includes(item.cartId)),
    );
  }

  decrease(itemId: ShoppingCartItem["cartId"]) {
    const data = getObjectItem<ShoppingCartItem[]>(this.cartStorageKey);
    if (!data) {
      return;
    }
    setObjectItem(
      this.cartStorageKey,
      data.map((cartItem) => {
        if (itemId === cartItem.cartId && cartItem.amount > 1) {
          return { ...cartItem, amount: --cartItem.amount };
        }
        return cartItem;
      }),
    );
  }

  clear() {
    const data = getObjectItem<ShoppingCartItem[]>(this.cartStorageKey);
    if (!data) {
      return;
    }
    removeItem(this.cartStorageKey);
  }

  getAll(): ShoppingCartItem[] {
    const data = getObjectItem<ShoppingCartItem[]>(this.cartStorageKey);

    return data ?? [];
  }
}

export default ShoppingCart;
