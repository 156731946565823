import { Prisma } from "@prisma/client";
import { captureException as sentryCaptureException } from "@sentry/nextjs";
import { z } from "zod";

interface Error {
  name: string;
  message: string;
  code?: number;
  error?: string;
  errors?: Record<string, string>;
}
export const captureException = (error: unknown): void => {
  sentryCaptureException(error);
  console.error(error);
};

export const formatZodErrors = (
  issues: z.ZodIssue[],
): Record<string, string> => {
  return issues.reduce((acc, curr) => {
    const key = curr.path.join(".");
    return {
      ...acc,
      [key]: curr.message,
    };
  }, {});
};

export const formatErrorResponse = (error: any | z.ZodError): Error => {
  if (error instanceof z.ZodError) {
    return {
      name: "VALIDATION",
      message: "Request validation failed!",
      errors: formatZodErrors(error.issues),
    };
  }

  if (
    error instanceof Prisma.PrismaClientKnownRequestError ||
    error instanceof Prisma.PrismaClientValidationError ||
    error instanceof Prisma.PrismaClientUnknownRequestError
  ) {
    if (process.env.NODE_ENV === "development") {
      console.error(error);
    }
    return {
      name: "DATABASE",
      message:
        "Something went wrong. Please try again or contact support: contact@uniqly.io.",
      error:
        "Something went wrong. Please try again or contact support: contact@uniqly.io.",
    };
  }

  return {
    name: error instanceof z.ZodError ? "VALIDATION" : error.name,
    code: error.code,
    message:
      error instanceof z.ZodError
        ? "Request validation failed!"
        : error.message,
    errors:
      error instanceof z.ZodError ? formatZodErrors(error.issues) : undefined,
  };
};
