import { UrlObject } from "url";

import NextLink from "next/link";

import { FC, forwardRef, ReactNode, SyntheticEvent } from "react";

import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";

import styles from "./styles";

export type CustomLinkProps = {
  href?: string | UrlObject;
  noDecoration?: boolean;
  noDecorationOnHover?: boolean;
  noMarginRight?: boolean;
  color?: "gray" | "primary" | "secondary";
  sx?: SxProps<Theme>;
  target?: string;
  rel?: string;
  onClick?: (event: SyntheticEvent) => void;
  children: ReactNode;
  size?: "small" | "medium" | "large";
};

const CustomLink: FC<CustomLinkProps> = forwardRef<
  HTMLAnchorElement,
  CustomLinkProps
>(function Link(props, ref) {
  const {
    href,
    noDecoration = false,
    noDecorationOnHover = false,
    noMarginRight,
    color,
    sx = [],
    size,
    children,
    ...rest
  } = props;

  const customLinkStyles = [
    styles.link,
    Boolean(noDecoration) && styles.noDecoration,
    Boolean(noDecorationOnHover) && styles.noDecorationOnHover,
    Boolean(noMarginRight) && styles.noMarginRight,
    !!size && styles[size],
    !!color && styles[color],
    ...(Array.isArray(sx) ? sx : [sx]),
  ];

  if (!href) {
    return <Box sx={customLinkStyles}>{children}</Box>;
  }

  return typeof href !== "string" || href?.indexOf?.("http") === -1 ? (
    <Box
      component={NextLink}
      href={href}
      {...rest}
      sx={customLinkStyles}
      ref={ref}
      className="uniqly-link"
    >
      {children}
    </Box>
  ) : (
    <Box
      component="a"
      {...rest}
      href={href}
      sx={customLinkStyles}
      ref={ref}
      className="uniqly-link"
    >
      {children}
    </Box>
  );
});

export default CustomLink;
