import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ShoppingCartItem } from "modules/Cart/ShoppingCart.interface";
import ShoppingCart from "modules/Cart/ShoppingCart";

import { RootState } from "redux/store";

type SliceState = {
  items: ShoppingCartItem[];
  isModalOpen: boolean;
};

const shoppingCart = new ShoppingCart();

const initialState: SliceState = {
  items: shoppingCart.getAll(),
  isModalOpen: false,
};

const shoppingCartSlices = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    addItem: (
      state,
      action: PayloadAction<Omit<ShoppingCartItem, "cartId">>,
    ) => {
      shoppingCart.add(action.payload);
      state.items = shoppingCart.getAll();
    },
    removeItems: (
      state,
      action: PayloadAction<ShoppingCartItem["cartId"][]>,
    ) => {
      shoppingCart.remove(action.payload);
      state.items = shoppingCart.getAll();
    },
    toggleModal: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
    decreaseAmountById: (
      state,
      action: PayloadAction<ShoppingCartItem["cartId"]>,
    ) => {
      shoppingCart.decrease(action.payload);
      state.items = shoppingCart.getAll();
    },
    clearCart: (state) => {
      shoppingCart.clear();
      state.items = shoppingCart.getAll();
    },
  },
});

export const selectCartItems = (state: RootState) => state.shoppingCart.items;
export const selectCartModalOpen = (state: RootState) =>
  state.shoppingCart.isModalOpen;

export const {
  addItem,
  removeItems,
  decreaseAmountById,
  toggleModal,
  clearCart,
} = shoppingCartSlices.actions;

export default shoppingCartSlices.reducer;
