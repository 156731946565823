import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { configureStore, combineReducers } from "@reduxjs/toolkit";

import metamaskReducer from "./slices/metamask";
import tokensReducer from "./slices/tokens";
import loggerReducer from "./slices/logger";
import shoppingCartReducer from "./slices/shoppingCart";

export const rootReducer = combineReducers({
  metamask: metamaskReducer,
  tokens: tokensReducer,
  logger: loggerReducer,
  shoppingCart: shoppingCartReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
