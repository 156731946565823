import { FC } from "react";

import Close from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Button from "components/CustomButton";
import Divider from "components/Divider";

import styles from "./styles";

interface ModalProps {
  isOpen?: boolean;
  handleClose?: () => void;
  title?: string;
  children?: JSX.Element | JSX.Element[] | string;
  disableBackdropClick?: boolean;
  hideCloseButton?: boolean;
  darkBackground?: boolean;
}

const Modal: FC<ModalProps> = ({
  isOpen = false,
  handleClose,
  title,
  children,
  disableBackdropClick = false,
  hideCloseButton = false,
  darkBackground = false,
}) => {
  return (
    <Dialog
      sx={{
        "&.MuiDialog-root": darkBackground
          ? styles.darkBackgroundModal
          : styles.modalRoot,
        "& .MuiDialog-paper": styles.modal,
        "& .MuiDialog-paperWidthSm": styles.modalWidth,
      }}
      disableScrollLock // disabled because modal when opened is adding padding to the body element
      open={isOpen}
      TransitionComponent={Slide}
      keepMounted
      onClose={(_, reason) =>
        (disableBackdropClick && reason === "backdropClick") || !handleClose
          ? null
          : handleClose()
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        width="100%"
        sx={hideCloseButton ? styles.modalDisabledCloseButton : {}}
      >
        <Box>
          {!hideCloseButton && (
            <Button simple sx={styles.modalCloseButton} onClick={handleClose}>
              <Close />
            </Button>
          )}
        </Box>

        {title && (
          <Box display="flex" justifyContent="center" width="100%">
            <Typography component="h3" variant="h3" align="center">
              {title}
            </Typography>
          </Box>
        )}
      </Box>

      {title && (
        <Box width="60%" m="auto">
          <Divider />
        </Box>
      )}
      <DialogContent sx={styles.modalBody}>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
