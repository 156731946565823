import { CHAIN_IDS } from "enums/chainIds";

import { Chain } from "interfaces/Chain.interface";

export const STORAGE_CHAIN_ID_NAME = "chainId";

export default [
  {
    chainId: 56,
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      name: "Binance Smart Chain",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  {
    chainId: 43114,
    chainName: "Avalanche Mainnet C-Chain",
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://cchain.explorer.avax.network/"],
  },
];

//base on https://chainid.network/chains.json
export const CHAINS: Chain[] = [
  {
    name: "Ethereum Mainnet",
    chain: "Ethereum",
    network: "mainnet",
    openseaChain: "ethereum",
    icon: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/icons/eth.svg`,
    rpc: [
      process.env.INFURA_ETH_API_URL as string,
      "https://api.mycryptoapi.com/eth",
      "https://cloudflare-eth.com",
    ],
    faucets: [],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    infoURL: "https://ethereum.org",
    shortName: "eth",
    chainId: CHAIN_IDS.MAIN,
    networkId: CHAIN_IDS.MAIN,
    slip44: 60,
    ens: {
      registry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
    },
    explorers: [
      {
        name: "etherscan",
        url: "https://etherscan.io",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Ethereum Testnet Sepolia",
    chain: "Ethereum",
    network: "sepolia",
    openseaChain: "sepolia",
    icon: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/icons/eth.svg`,
    rpc: [process.env.INFURA_ETH_API_URL as string, "https://rpc.sepolia.org"],
    faucets: ["https://sepoliafaucet.com/"],
    nativeCurrency: {
      name: "SepoliaETH",
      symbol: "ETH",
      decimals: 18,
    },
    infoURL: "https://sepolia.dev",
    shortName: "sep",
    chainId: CHAIN_IDS.SEPOLIA,
    networkId: CHAIN_IDS.SEPOLIA,
    ens: {
      registry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
    },
    explorers: [
      {
        name: "etherscan-sepolia",
        url: "https://sepolia.etherscan.io",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Polygon Mainnet",
    chain: "Polygon",
    network: "mainnet",
    openseaChain: "matic",
    icon: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/icons/matic.svg`,
    rpc: [
      process.env.INFURA_POLYGON_API_URL as string,
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.matic.network",
      "https://matic-mainnet.chainstacklabs.com",
      "https://rpc-mainnet.maticvigil.com",
      "https://rpc-mainnet.matic.quiknode.pro",
      "https://matic-mainnet-full-rpc.bwarelabs.com",
    ],
    faucets: [],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    infoURL: "https://polygon.technology/",
    shortName: "MATIC",
    chainId: CHAIN_IDS.POLYGON_MAINNET,
    networkId: CHAIN_IDS.POLYGON_MAINNET,
    slip44: 966,
    explorers: [
      {
        name: "polygonscan",
        url: "https://polygonscan.com",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Polygon Testnet Amoy",
    chain: "Polygon",
    network: "testnet",
    openseaChain: "amoy",
    icon: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/icons/matic.svg`,
    rpc: [
      process.env.INFURA_POLYGON_API_URL as string,
      "https://rpc-amoy.polygon.technology",
      "https://polygon-amoy-bor-rpc.publicnode.com",
    ],
    faucets: ["https://faucet.polygon.technology/"],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    infoURL: "https://polygon.technology/",
    shortName: "maticamoy",
    chainId: CHAIN_IDS.POLYGON_TESTNET,
    networkId: CHAIN_IDS.POLYGON_TESTNET,
    explorers: [
      {
        name: "polygonscan",
        url: "https://amoy.polygonscan.com",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "localhost",
    chain: "Localhost",
    network: "testnet",
    icon: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/icons/eth.svg`,
    rpc: [process.env.INFURA_ETH_API_URL as string],
    faucets: ["https://faucet.polygon.technology/"],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    infoURL: "https://polygon.technology/",
    shortName: "loc",
    chainId: 1337,
    networkId: 1337,
    explorers: [
      {
        name: "polygonscan",
        url: "https://mumbai.polygonscan.com",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Binance Smart Chain Testnet",
    chain: "BSC",
    network: "Chapel",
    openseaChain: "bsctestnet",
    icon: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/icons/bsc.svg`,
    rpc: [
      "https://data-seed-prebsc-1-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-1-s2.binance.org:8545",
      "https://data-seed-prebsc-2-s2.binance.org:8545",
      "https://data-seed-prebsc-1-s3.binance.org:8545",
      "https://data-seed-prebsc-2-s3.binance.org:8545",
    ],
    faucets: ["https://testnet.binance.org/faucet-smart"],
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "tBNB",
      decimals: 18,
    },
    infoURL: "https://testnet.binance.org/",
    shortName: "bnbt",
    chainId: CHAIN_IDS.BSC_TESTNET,
    networkId: CHAIN_IDS.BSC_TESTNET,
    explorers: [
      {
        name: "bscscan-testnet",
        url: "https://testnet.bscscan.com",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Binance Smart Chain Mainnet",
    chain: "BSC",
    network: "mainnet",
    openseaChain: "bsc",
    icon: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/icons/bsc.svg`,
    rpc: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org",
    ],
    faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18,
    },
    infoURL: "https://www.binance.org",
    shortName: "bnb",
    chainId: CHAIN_IDS.BSC,
    networkId: CHAIN_IDS.BSC,
    slip44: 714,
    explorers: [
      {
        name: "bscscan",
        url: "https://bscscan.com",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Base",
    chain: "ETH",
    network: "base",
    rpc: [
      "https://mainnet.base.org/",
      "https://developer-access-mainnet.base.org/",
      "https://base.gateway.tenderly.co",
      "wss://base.gateway.tenderly.co",
      "https://base-rpc.publicnode.com",
      "wss://base-rpc.publicnode.com",
    ],
    faucets: [],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    infoURL: "https://base.org",
    shortName: "base",
    chainId: 8453,
    networkId: 8453,
    icon: "base",
    explorers: [
      {
        name: "basescan",
        url: "https://basescan.org",
        standard: "none",
      },
    ],
  },
  {
    name: "Base Sepolia Testnet",
    chain: "ETH",
    network: "basesepolia",
    rpc: [
      "https://sepolia.base.org",
      "https://base-sepolia-rpc.publicnode.com",
      "wss://base-sepolia-rpc.publicnode.com",
    ],
    faucets: [],
    nativeCurrency: {
      name: "Sepolia Ether",
      symbol: "ETH",
      decimals: 18,
    },
    infoURL: "https://base.org",
    shortName: "basesep",
    chainId: 84532,
    networkId: 84532,
    slip44: 1,
    icon: "baseTestnet",
    explorers: [
      {
        name: "basescan",
        url: "https://sepolia.basescan.org/",
        standard: "EIP3091",
      },
    ],
  },
];
