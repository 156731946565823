import { utils } from "ethers";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "redux/store";

import { THEME_MODES } from "enums/themeModes";
import { CHAIN_IDS } from "enums/chainIds";
import { SIGN_IN_TYPE } from "enums/signInTypes";

import { AuthUser } from "interfaces/AuthUser.interface";

import { API_ROUTES } from "config/routes";
import { STORAGE_CHAIN_ID_NAME } from "config/networks";

import {
  getSupportedChainIds,
  fromWeiToEther,
  getEthereumChainId,
} from "utils/blockchain";
import { getItem, setItem } from "utils/storage";

import fetcher from "lib/fetchJson";

export const fetchLoggedInUser = createAsyncThunk<AuthUser | null>(
  "metamaskLoggedInUser/fetch",
  async () => {
    return fetcher(API_ROUTES.AUTH_ME);
  },
);

const storageChainId = getItem(STORAGE_CHAIN_ID_NAME);
const chainId =
  storageChainId !== "" &&
  getSupportedChainIds().includes(parseInt(storageChainId))
    ? (parseInt(storageChainId) as CHAIN_IDS)
    : getEthereumChainId(); // TODO: Check if it works

type SliceState = {
  address: string | null;
  custodialAddress: string | null;
  user: {
    isLoggedIn: boolean;
    email: string | null;
    nickname: string | null;
    referral: string | null;
    authenticated: string | null;
  };
  signInType: SIGN_IN_TYPE | null;
  isUserFetched: boolean;
  chainId: CHAIN_IDS;
  uniqBalance: number;
  currentNetworkBalance: number;
  isAuthModalOpen: boolean;
  authModalTab: 0 | 1;
  themeMode: THEME_MODES;
};

const initialState: SliceState = {
  address: null,
  custodialAddress: null,
  chainId,
  user: {
    isLoggedIn: false,
    email: null,
    nickname: null,
    referral: null,
    authenticated: null,
  },
  signInType: null,
  uniqBalance: 0,
  currentNetworkBalance: 0,
  isAuthModalOpen: false,
  authModalTab: 0,
  isUserFetched: false,
  themeMode: THEME_MODES.DARK,
};

const metamaskSlice = createSlice({
  name: "metamask",
  initialState,
  reducers: {
    setLoggedInUser: (state, action: PayloadAction<AuthUser>) => {
      state.user.isLoggedIn = action.payload.isLoggedIn || false;
      state.user.email = action.payload.email || null;
      state.user.nickname = action.payload.nickname || null;
      state.user.referral = action.payload.referral || null;
      state.user.authenticated = action.payload.authenticated || null;
      state.address = action.payload.metamaskAddress || null;
      state.custodialAddress = action.payload.custodialAddress || null;
      state.signInType = action.payload.signInType || null;
      state.isUserFetched = true;
    },
    setLoggedUserEmail: (
      state,
      action: PayloadAction<SliceState["user"]["email"]>,
    ) => {
      state.user.email = action.payload;
    },
    setLoggedUserNickname: (
      state,
      action: PayloadAction<SliceState["user"]["nickname"]>,
    ) => {
      state.user.nickname = action.payload;
    },
    clearUser: (state) => {
      state.address = null;
      state.custodialAddress = null;
      state.user = initialState.user;
      state.isUserFetched = true;
      state.uniqBalance = 0;
      state.currentNetworkBalance = 0;
      state.signInType = null;
    },
    setUniqBalance: (state, action: PayloadAction<string>) => {
      state.uniqBalance = parseInt(utils.formatEther(action.payload));
    },
    setCurrentNetworkBalance: (state, action: PayloadAction<string>) => {
      state.currentNetworkBalance = fromWeiToEther(action.payload) ?? 0;
    },
    setChainId: (state, action: PayloadAction<CHAIN_IDS>) => {
      state.chainId = action.payload;
      setItem(STORAGE_CHAIN_ID_NAME, String(action.payload));
    },
    setThemeMode: (state, action: PayloadAction<THEME_MODES>) => {
      state.themeMode = action.payload;
    },
    setAuthModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isAuthModalOpen = action.payload;
    },
    setAuthModalTab: (state, action: PayloadAction<0 | 1>) => {
      state.authModalTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoggedInUser.fulfilled, (state, action) => {
        state.user.isLoggedIn = action.payload?.isLoggedIn || false;
        state.user.email = action.payload?.email || null;
        state.user.nickname = action.payload?.nickname || null;
        state.user.referral = action.payload?.referral || null;
        state.user.authenticated = action.payload?.authenticated || null;
        state.address = action.payload?.metamaskAddress || null;
        state.custodialAddress = action.payload?.custodialAddress || null;
        state.custodialAddress = action.payload?.custodialAddress || null;
        state.signInType = action.payload?.signInType || null;
        state.isUserFetched = true;
      })
      .addCase(fetchLoggedInUser.rejected, (state) => {
        state.address = null;
        state.custodialAddress = null;
        state.user = initialState.user;
        state.uniqBalance = 0;
        state.isUserFetched = true;
        state.signInType = null;
      });
  },
});

export const selectUserEmail = (state: RootState) => state.metamask?.user.email;
export const selectUserNickname = (state: RootState) =>
  state.metamask?.user.nickname;
export const selectAddress = (state: RootState) => state.metamask?.address;
export const selectCustodialAddress = (state: RootState) =>
  state.metamask?.custodialAddress;
export const selectAvailableAddress = (state: RootState) =>
  (
    state.metamask?.address ??
    state.metamask?.custodialAddress ??
    ""
  ).toLowerCase();
export const selectIsOnlyCustodialAddress = (state: RootState) =>
  !state.metamask?.address;
export const selectChainId = (state: RootState) => state.metamask?.chainId;
export const selectUser = (state: RootState) => state.metamask?.user;
export const selectUniqBalance = (state: RootState) =>
  state.metamask?.uniqBalance;
export const selectCurrentNetworkBalance = (state: RootState) =>
  state.metamask?.currentNetworkBalance;
export const selectAuthModalOpen = (state: RootState) =>
  state.metamask?.isAuthModalOpen;
export const selectAuthModalTab = (state: RootState) =>
  state.metamask?.authModalTab;
export const selectThemeMode = (state: RootState) => state.metamask?.themeMode;
export const selectIsUserFetched = (state: RootState) =>
  state.metamask?.isUserFetched;
export const selectSignInType = (state: RootState) =>
  state.metamask?.signInType;

export const {
  setLoggedUserEmail,
  setLoggedUserNickname,
  setLoggedInUser,
  clearUser,
  setUniqBalance,
  setCurrentNetworkBalance,
  setChainId,
  setAuthModalOpen,
  setAuthModalTab,
  setThemeMode,
} = metamaskSlice.actions;

export default metamaskSlice.reducer;
