export enum CHAIN_IDS {
  MAIN = 1,
  SEPOLIA = 11155111,
  LOCAL = 1337,
  POLYGON_MAINNET = 137,
  POLYGON_TESTNET = 80002,
  BSC = 56,
  BSC_TESTNET = 97,
  BASE = 8453,
  BASE_SEPOLIA = 84532,
}
