import { providers } from "ethers";

import { CHAIN_IDS } from "enums/chainIds";

class ProviderFactory {
  static create(chainId: CHAIN_IDS): providers.JsonRpcProvider {
    if (chainId === CHAIN_IDS.MAIN || chainId === CHAIN_IDS.SEPOLIA) {
      return ProviderFactory.createEthProvider();
    }

    if (chainId === CHAIN_IDS.BSC || chainId === CHAIN_IDS.BSC_TESTNET) {
      return ProviderFactory.createBscProvider();
    }

    if (chainId === CHAIN_IDS.BASE || chainId === CHAIN_IDS.BASE_SEPOLIA) {
      return ProviderFactory.createBaseProvider();
    }

    return ProviderFactory.createPolygonProvider();
  }

  static createPolygonProvider(): providers.JsonRpcProvider {
    return new providers.JsonRpcProvider(process.env.INFURA_POLYGON_API_URL);
  }

  static createPolygonWSProvider(): providers.WebSocketProvider {
    return new providers.WebSocketProvider(
      process.env.NEXT_PUBLIC_INFURA_POLYGON_WS_URL ?? "",
    );
  }

  static createWS(chainId: CHAIN_IDS): providers.WebSocketProvider {
    if (chainId === CHAIN_IDS.MAIN || chainId === CHAIN_IDS.SEPOLIA) {
      return new providers.WebSocketProvider(
        process.env.NEXT_PUBLIC_INFURA_ETH_WS_URL ?? "",
      );
    }

    if (chainId === CHAIN_IDS.BSC || chainId === CHAIN_IDS.BSC_TESTNET) {
      return new providers.WebSocketProvider(
        process.env.NEXT_PUBLIC_INFURA_BSC_WS_URL ?? "",
      );
    }

    return ProviderFactory.createPolygonWSProvider();
  }

  static createEthProvider(): providers.JsonRpcProvider {
    return new providers.JsonRpcProvider(process.env.INFURA_ETH_API_URL);
  }

  static createBscProvider(): providers.JsonRpcProvider {
    return new providers.JsonRpcProvider(process.env.BSC_RPC);
  }

  static createBaseProvider(): providers.JsonRpcProvider {
    return new providers.JsonRpcProvider(process.env.BASE_RPC);
  }

  static createClientWeb3(): providers.JsonRpcProvider | null {
    if (process.browser && window.ethereum) {
      return new providers.Web3Provider(window.ethereum as any, "any");
    }

    return null;
  }
}

export default ProviderFactory;
