import Head from "next/head";
import { AppProps } from "next/app";

import "react-toastify/dist/ReactToastify.css";
import "./../main.css";
import "./../styles/swagger-ui.css";
import "./../node_modules/swiper/modules/navigation.min.css";

import { ReactElement, useMemo } from "react";
import { Provider, useSelector } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";

import { Poppins } from "next/font/google";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, WagmiConfig, createConfig } from "wagmi";
import {
  bsc,
  bscTestnet,
  sepolia,
  localhost,
  mainnet,
  polygon,
  polygonAmoy,
} from "wagmi/chains";

import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "theme";

import store from "redux/store";
import { selectThemeMode } from "redux/slices/metamask";

import ToastContainer from "components/ToastContainer";
import WrongNetworkModal from "components/WrongNetworkModal";
import CookiesBox from "components/CookiesBox";

import useAppInit from "hooks/useAppInit";

import { CHAIN_IDS } from "enums/chainIds";
import { THEME_MODES } from "enums/themeModes";

import { getEthereumChainId } from "utils/blockchain";

type MyAppProps = {
  Component?: ReactElement;
  emotionCache;
  pageProps;
};

const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID as string;

const { chains, publicClient } = configureChains(
  [
    ...(getEthereumChainId() === CHAIN_IDS.MAIN
      ? [mainnet, bsc, polygon]
      : [sepolia, localhost, bscTestnet, polygonAmoy]),
  ],
  [w3mProvider({ projectId })],
);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ chains, projectId }),
  publicClient,
});

// 3. Configure modal ethereum client
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const ThemeProviderWithRedux = ({ children }) => {
  const storeTheme = useSelector(selectThemeMode);

  const activeTheme = useMemo(
    () =>
      theme(
        storeTheme === THEME_MODES.LIGHT ? THEME_MODES.LIGHT : THEME_MODES.DARK,
      ),
    [storeTheme],
  );

  return <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>;
};

const InitApp = () => {
  useAppInit();

  return null;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600", "800"],
  style: ["italic", "normal"],
  display: "swap",
  preload: true,
});

const MyApp = ({
  Component,
  pageProps,
}: AppProps & MyAppProps): ReactElement => {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <title>Uniqly.io</title>
      </Head>

      <Provider store={store}>
        <WagmiConfig config={wagmiConfig}>
          <ThemeProviderWithRedux>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <InitApp />
              <CssBaseline />
              <GlobalStyles
                styles={{
                  body: {
                    fontSize: "62.5%",
                    ".TransactionWindow": { zIndex: "9999 !important" },
                    fontFamily: poppins.style.fontFamily,
                  },
                }}
              />
              <Component {...pageProps} />
              <WrongNetworkModal />
              <CookiesBox />
              <ToastContainer />
            </QueryClientProvider>
          </ThemeProviderWithRedux>
        </WagmiConfig>
      </Provider>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-z-index": "10000",
        }}
        explorerRecommendedWalletIds={[
          "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // MetaMask
        ]}
      />
    </>
  );
};

export default MyApp;
