import { UrlObject } from "url";

import NextLink from "next/link";

import React, { FC, forwardRef, ReactNode, ElementType } from "react";

import Button, { ButtonProps } from "@mui/material/Button";

import Loader from "components/Loader";

import styles from "./styles";

export type CustomButtonProps = {
  round?: boolean;
  children?: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  simple?: boolean;
  size?: string | void;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  fileButton?: boolean;
  minWidth?: boolean;
  isLoading?: boolean;
  loaderLabel?: string;
  target?: string;
  rel?: string;
  component?: ElementType;
  href?: string | UrlObject;
} & Omit<ButtonProps, "href">;

const CustomButton: FC<CustomButtonProps> = forwardRef((props, ref) => {
  const {
    color = "primary",
    round = false,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    minWidth,
    isLoading,
    sx = [],
    loaderLabel = "Processing...",
    href,
    ...rest
  } = props;

  const buttonSx = [
    styles.button,
    size === "small" && styles.sm,
    size === "large" && styles.lg,
    color && styles[color],
    round && styles.round,
    fullWidth && styles.fullWidth,
    disabled && styles.disabled,
    simple && styles.simple,
    block && styles.block,
    link && styles.link,
    justIcon && styles.justicon,
    minWidth && styles.minWidth,
    ...(Array.isArray(sx) ? sx : [sx]),
  ];

  if (href && (typeof href !== "string" || href.indexOf?.("http") === -1)) {
    return (
      <NextLink href={href} style={{ display: "contents" }}>
        <Button {...rest} ref={ref} sx={buttonSx} component="span">
          {isLoading ? (
            <Loader size={19} label={loaderLabel} inherit />
          ) : (
            children
          )}
        </Button>
      </NextLink>
    );
  }

  return (
    <Button
      {...rest}
      href={href || undefined}
      ref={ref}
      sx={buttonSx}
      disableRipple
    >
      {isLoading ? <Loader size={19} label={loaderLabel} inherit /> : children}
    </Button>
  );
});

CustomButton.displayName = "CustomButton";

export default CustomButton;
