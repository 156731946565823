import orderBy from "lodash/orderBy";
import isNil from "lodash/isNil";

import { Contract, utils, providers, Signer, BigNumberish } from "ethers";

import { AbiContract } from "types/contracts";

import { TOKEN_STANDARDS } from "enums/tokenStandards";
import {
  BSC_TOKENS,
  PAYMENT_TOKENS,
  POLYGON_TOKENS,
} from "enums/paymentTokens";
import { CHAIN_IDS } from "enums/chainIds";
import { CONTRACTS } from "enums/contracts";

import { NFTToken } from "interfaces/NFTToken.interface";

import { ETHER_ADDRESS_O, ETHER_DECIMAL } from "config/addresses";
import { CHAINS } from "config/networks";

import { captureException } from "utils/errors";

import ProviderFactory from "services/blockchain/ProviderFactory";

import fetcher from "lib/fetchJson";

const DEFAULT_GAS_PRICE = 40000000000;
const DEFAULT_GAS_LIMIT = 250000;

export const getEstimatedGasPrice = async (): Promise<number | string> => {
  const provider = ProviderFactory.createClientWeb3();
  return getEstimatedGasPriceWithProvider(provider);
};

export const getEstimatedGasPriceWithProvider = async (
  provider: providers.JsonRpcProvider | null,
): Promise<number | string> => {
  try {
    if (!provider) {
      return DEFAULT_GAS_PRICE;
    }
    const gasPrice = await provider.getGasPrice();
    return (gasPrice.toNumber() * 1.2).toFixed(0);
  } catch {
    return DEFAULT_GAS_PRICE;
  }
};

export const getEstimatedGasLimit = (chainId, itemAmount = 1, currency) => {
  try {
    if (isPolygonNetwork(chainId) && isNativeToken(currency)) {
      return 300000 * itemAmount;
    }
    if (isPolygonNetwork(chainId)) {
      return 340000 * itemAmount;
    }
    if (isEthNetwork(chainId) && !isNativeToken(currency)) {
      return 350000 * itemAmount;
    }

    return DEFAULT_GAS_LIMIT;
  } catch {
    return DEFAULT_GAS_LIMIT;
  }
};

export const metamaskWelcomeMessage = (): string => {
  return `Welcome to Uniqly.io. Click to sign in and accept the Uniqly Terms of Service https://www.uniqly.io/terms. This request will not trigger a blockchain transaction or cost any gas fees.`;
};

export const loadContract = (
  contract: AbiContract,
  chainId: CHAIN_IDS,
): Contract | null => {
  try {
    const provider = ProviderFactory.createClientWeb3();
    if (!isChainIdSupported(chainId) || !provider) {
      return null;
    }

    return loadContractWithWeb3(provider.getSigner(), contract, chainId);
  } catch {
    return null;
  }
};

export const loadContractWithWeb3 = (
  provider: providers.JsonRpcProvider | Signer,
  contract: AbiContract,
  chainId: CHAIN_IDS,
): Contract => {
  const deployedNetwork = contract.networks[chainId];

  return new Contract(deployedNetwork.address, contract.abi, provider);
};

export const getOwner = async (
  contract: Contract,
  tokenId: number,
): Promise<string> => {
  try {
    return await contract.ownerOf(tokenId);
  } catch {
    return ETHER_ADDRESS_O;
  }
};

export const isOwned = async (contract: Contract, tokenId: number) => {
  const owner = await getOwner(contract, tokenId);

  return owner !== ETHER_ADDRESS_O;
};

export const fetchNFTMetaDataWithProvider = async (
  contractAddress: string,
  tokenId: number,
  provider: providers.JsonRpcProvider,
  includeShipping = true,
) => {
  const abi = [
    "function tokenURI(uint256 tokenId) external view returns (string memory)",
    "function ownerOf(uint256 _tokenId) external view returns (address)",
    "function baseTokenURI() public view returns (string memory)",
  ];
  const contract = new Contract(contractAddress, abi, provider);

  const baseUrl = await contract.baseTokenURI();
  const owner = await getOwner(contract, tokenId);

  try {
    const metadata = await fetcher(
      `${baseUrl}${tokenId}${
        !includeShipping
          ? `?shipping=false&description=false`
          : "?description=false"
      }`,
      null,
      "GET",
      {
        "Content-Type": "application/json",
        "X-Referrer": process.env.NEXT_PUBLIC_APP_URL as string,
      },
      null,
    );

    return { metadata, owner, error: false };
  } catch (e) {
    return { metadata: {}, owner: ETHER_ADDRESS_O, error: true };
  }
};

export const fetchNFTOwner = async (
  contractAddress: string,
  tokenId: number,
  provider: providers.JsonRpcProvider,
) => {
  try {
    const abi = [
      "function ownerOf(uint256 _tokenId) external view returns (address)",
    ];
    const contract = new Contract(contractAddress, abi, provider);
    const owner = await getOwner(contract, tokenId);
    return { owner, error: false };
  } catch (e) {
    return { metadata: {}, owner: ETHER_ADDRESS_O, error: true };
  }
};

export function formatAccount(account: string | null | undefined): string {
  if (isNil(account)) {
    return "-";
  }

  return account
    ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
    : "";
}

const tokensOfOwner = async (
  chainId: CHAIN_IDS,
  contract: Contract,
  address: string,
): Promise<NFTToken[]> => {
  try {
    const ownItems = await contract.tokensOfOwner(address);
    if (ownItems.length > 0) {
      return ownItems.map((item) => ({
        contractAddress: contract.address,
        tokenId: parseInt(item.toString()),
        chainId,
      }));
    }

    return [];
  } catch {
    return [];
  }
};

export const fetchWalletContractsNFTs = async (
  provider: providers.JsonRpcProvider,
  contracts: Contract[],
  address: string,
): Promise<NFTToken[]> => {
  const { chainId } = await provider.getNetwork();
  const promises = contracts
    .filter((contract) => contract !== null)
    .map((contract) => tokensOfOwner(chainId, contract, address));
  const results = await Promise.all(promises);
  const tokensIds = results.reduce(function (arr, row) {
    return arr.concat(row);
  }, []);

  return orderBy(tokensIds, ["tokenId"], ["desc"]);
};

export const fromWeiToEther = (wei: BigNumberish): number | null => {
  if (wei) return Number(utils.formatEther(String(wei)));
  return null;
};

export const fromEthToWei = (
  eth: string | number | null | undefined,
): string | null => {
  if (!isNil(eth)) return utils.parseEther(String(eth)).toString();

  return null;
};

export const fromValueToWei = (value, decimal) => {
  if (!isNil(value)) {
    return utils
      .parseUnits(value.toString().replace(",", ""), decimal)
      .toString();
  }
  return null;
};

export const isAddressesEquals = (
  address1?: string | null,
  address2?: string | null,
) => {
  if (!address1 || !address2) return false;
  return address1.toLowerCase() === address2.toLowerCase();
};

//base on https://eips.ethereum.org/EIPS/eip-1474#error-codes
//base on https://eips.ethereum.org/EIPS/eip-1193#provider-errors
export const getMetamaskMessageError = (err, defaultValue) => {
  if ("code" in err) {
    switch (err.code) {
      case "ACTION_REJECTED":
      case 4001:
        return "You've rejected the transaction.";
      case 4100:
        return "The requested method and/or account has not been authorized by the user.";
      case 4200:
        return "The Provider does not support the requested method.";
      case 4900:
        return "The Provider is disconnected from all chains.";
      case 4901:
        return "The Provider is not connected to the requested chain.";
      case -32700:
        return "Invalid JSON";
      case -32600:
        return "JSON is not a valid request object";
      case -32601:
        return "Method does not exist";
      case -32602:
        return "Invalid method parameters";
      case -32603:
        return "Internal JSON-RPC error";
      case -32000:
        return "Missing or invalid parameters";
      case -32001:
        return "Requested resource not found";
      case -32002:
        return "Requested resource not available";
      case -32003:
        return "Transaction creation failed";
      case -32004:
        return "Method is not implemented";
      case -32005:
        return "Request exceeds defined limit";
      case "UNPREDICTABLE_GAS_LIMIT":
        return "Wrong gas estimation. Please try again or contact support";
      case "CALL_EXCEPTION":
        return "Something went wrong. Please try again or contact support";
      case "INSUFFICIENT_FUNDS":
        return "Insufficient funds for intrinsic transaction cost (transaction price + gas fee)";
      case -32006:
        return "Version of JSON-RPC protocol is not supported";
      default:
        return "message" in err ? err.message : defaultValue;
    }
  }

  return "message" in err ? err.message : defaultValue;
};

export const getMetamaskMessageErrorCode = (err) => {
  if ("code" in err) {
    return err.code;
  }
  return null;
};

export const ERC20Contract = (
  contractAddress: string,
  providerInstance?: providers.JsonRpcProvider,
): Contract | null => {
  try {
    const provider = providerInstance || ProviderFactory.createClientWeb3();
    if (provider) {
      return new Contract(
        contractAddress,
        CONTRACTS.TOKEN.abi,
        provider.getSigner(),
      );
    }

    return null;
  } catch (error) {
    captureException(error);
    return null;
  }
};

export const tokenDecimals = async (contract: Contract): Promise<number> => {
  try {
    return await contract.decimals();
  } catch {
    return ETHER_DECIMAL;
  }
};

export const convertToWei = async (
  paymentTokenAddress: string,
  value: number,
  provider: providers.JsonRpcProvider,
  cache?: Record<string, any>,
): Promise<string> => {
  const getDecimals = async (): Promise<number> => {
    if (cache && cache[paymentTokenAddress]) {
      return cache[paymentTokenAddress];
    }
    const tokenContractInstance = new Contract(
      paymentTokenAddress,
      CONTRACTS.TOKEN.abi,
      provider,
    );
    const decimals = await tokenDecimals(tokenContractInstance);
    if (cache) {
      cache[paymentTokenAddress] = decimals;
    }

    return decimals;
  };

  const getWeiPrice = async (price: number) => {
    if (!isNativeCurrency(paymentTokenAddress)) {
      const decimals = await getDecimals();

      return fromValueToWei(price, decimals) as string;
    }

    return fromEthToWei(price) as string;
  };

  return getWeiPrice(value);
};

export const getAddressByContractAndChainId = (
  contract: AbiContract,
  chainId: CHAIN_IDS,
): string => {
  return contract.networks[chainId].address.toLowerCase();
};

export const getChainById = (chainId: CHAIN_IDS | null | undefined) => {
  if (chainId) {
    return CHAINS.find((chain) => chain.chainId === chainId);
  }
  return null;
};

export const isChainIdSupported = (chainId: CHAIN_IDS): boolean => {
  const supportedNetworks = getSupportedChainIds();

  return supportedNetworks.includes(chainId);
};

export const getSupportedChainIds = (): CHAIN_IDS[] =>
  (process.env.NEXT_PUBLIC_NETWORKS as string)
    .split(",")
    .map((chainId) => parseInt(chainId));

export const isNativeCurrency = (tokenAddress) =>
  tokenAddress === ETHER_ADDRESS_O;

export const isNativeToken = (token: string) =>
  [PAYMENT_TOKENS.ETH, POLYGON_TOKENS.MATIC, BSC_TOKENS.BNB]
    .map((item) => String(item))
    .includes(token);

export const isPolygonNetwork = (chainId: CHAIN_IDS): boolean =>
  [CHAIN_IDS.POLYGON_MAINNET, CHAIN_IDS.POLYGON_TESTNET].includes(chainId);

export const isBscNetwork = (chainId: CHAIN_IDS): boolean =>
  [CHAIN_IDS.BSC, CHAIN_IDS.BSC_TESTNET].includes(chainId);

export const isEthNetwork = (chainId: CHAIN_IDS): boolean =>
  [CHAIN_IDS.MAIN, CHAIN_IDS.SEPOLIA, CHAIN_IDS.LOCAL].includes(chainId);

export const isMainnet = (chainId: CHAIN_IDS): boolean =>
  [CHAIN_IDS.MAIN, CHAIN_IDS.POLYGON_MAINNET, CHAIN_IDS.BSC].includes(chainId);

export const isTestnet = (chainId: CHAIN_IDS): boolean =>
  [
    CHAIN_IDS.SEPOLIA,
    CHAIN_IDS.POLYGON_TESTNET,
    CHAIN_IDS.BSC_TESTNET,
  ].includes(chainId);

export const getPolygonChainId = (): CHAIN_IDS =>
  getSupportedChainIds().find((chainId) =>
    isPolygonNetwork(chainId),
  ) as CHAIN_IDS;

export const getEthereumChainId = (): CHAIN_IDS =>
  getSupportedChainIds().find(
    (chainId) => !isPolygonNetwork(chainId) && !isBscNetwork(chainId),
  ) as CHAIN_IDS;

export const getBscChainId = (): CHAIN_IDS =>
  getSupportedChainIds().find((chainId) => isBscNetwork(chainId)) as CHAIN_IDS;

export const getCollectionContractByAddress = (
  contractAddress: string,
  providerInstance?: providers.JsonRpcProvider,
): Contract | null => {
  try {
    const provider = providerInstance || ProviderFactory.createClientWeb3();
    if (provider) {
      return new Contract(
        contractAddress,
        CONTRACTS.COLLECTIONS.abi,
        provider.getSigner(),
      );
    }

    return null;
  } catch (error) {
    captureException(error);
    return null;
  }
};

export const contractWithBalanceOf = (
  contractAddress: string,
  providerInstance: providers.JsonRpcProvider,
  tokenStandard: TOKEN_STANDARDS,
): Contract | null => {
  try {
    const provider = providerInstance || ProviderFactory.createClientWeb3();
    if (provider) {
      let abi = [
        "function balanceOf(address owner) public view returns (uint256)",
        "function decimals() external view returns (uint8)",
      ];
      if (tokenStandard === TOKEN_STANDARDS.ERC1155) {
        abi = [
          "function balanceOf(address _owner, uint256 _id) external view returns (uint256)",
        ];
      }

      return new Contract(contractAddress, abi, provider);
    }

    return null;
  } catch (error) {
    captureException(error);
    return null;
  }
};
