import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectTokens } from "redux/slices/tokens";

import {
  BSC_TOKENS,
  PAYMENT_TOKENS,
  POLYGON_TOKENS,
} from "enums/paymentTokens";

export const usePaymentTokenAddress = (
  token: PAYMENT_TOKENS | POLYGON_TOKENS | BSC_TOKENS,
): string | null => {
  const [tokenAddress, setTokenAddress] = useState<string | null>(null);
  const tokens = useSelector(selectTokens);

  useEffect(() => {
    const result = tokens.find((item) => item.name === token);
    setTokenAddress(result ? result.contractAddress : null);
  }, [tokens, token]);

  return tokenAddress;
};
