export const getItem = (itemName: string): string => {
  if (!process.browser) {
    return "";
  }
  return (
    (typeof localStorage !== "undefined" &&
      window.localStorage.getItem(itemName)) ||
    ""
  );
};

export const setItem = (itemName: string, data: string): void => {
  if (typeof localStorage !== "undefined") {
    window.localStorage.setItem(itemName, data);
  }
};

export const removeItem = (itemName: string): void => {
  if (typeof localStorage !== "undefined") {
    window.localStorage.removeItem(itemName);
  }
};

export const getObjectItem = <T extends object>(itemName: string): T | null => {
  try {
    const data = getItem(itemName);

    return JSON.parse(data);
  } catch {
    return null;
  }
};

export const setObjectItem = <T extends object>(
  itemName: string,
  data: T,
): void => {
  setItem(itemName, JSON.stringify(data));
};
