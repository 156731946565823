import { Theme, alpha } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const dividerStyles = {
  horizontalDivider:
    (defaultColor: boolean, height = 1) =>
    (theme: Theme): SystemStyleObject<Theme> => ({
      height: `${height}px`,
      width: "100%",
      background: defaultColor
        ? theme.palette.divider
        : `linear-gradient(270deg, ${alpha(
            theme.palette.secondary.main,
            0.1,
          )}, ${alpha(theme.palette.secondary.main, 0.5)} 53.65%, ${alpha(
            theme.palette.secondary.main,
            0.1,
          )} 100%)`,
    }),
  verticalDivider:
    (defaultColor: boolean, width = 1) =>
    (theme: Theme): SystemStyleObject<Theme> => ({
      width: `${width}px`,
      height: "100%",
      background: defaultColor
        ? theme.palette.divider
        : `linear-gradient(180deg, ${alpha(
            theme.palette.secondary.main,
            0.1,
          )}, ${alpha(theme.palette.secondary.main, 0.5)} 53.65%, ${alpha(
            theme.palette.secondary.main,
            0.1,
          )} 100%)`,
    }),
  dark: (theme: Theme): SystemStyleObject<Theme> => ({
    background: theme.palette.background.paper,
  }),
};

export default dividerStyles;
