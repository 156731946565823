import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { selectAddress, setChainId } from "redux/slices/metamask";

import { CHAIN_IDS } from "enums/chainIds";

import { getChainById } from "utils/blockchain";

export const useChangeNetwork = () => {
  const dispatch = useDispatch();
  const metamaskAddress = useSelector(selectAddress);

  return useCallback(
    async (chainId: CHAIN_IDS) => {
      if (!metamaskAddress) {
        dispatch(setChainId(chainId));
        return;
      }
      try {
        if (window.ethereum) {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x" + chainId.toString(16) }],
          });
          dispatch(setChainId(chainId));
          toast.success("Network changed");
          return true;
        }
      } catch (e) {
        const chain = getChainById(chainId);
        if (e.code === 4902 && chain) {
          const params = {
            chainId: "0x" + chain.chainId.toString(16),
            chainName: chain.name,
            nativeCurrency: {
              name: chain.nativeCurrency.name,
              symbol: chain.nativeCurrency.symbol,
              decimals: chain.nativeCurrency.decimals,
            },
            rpcUrls: chain.rpc,
            blockExplorerUrls: [
              chain.explorers &&
              chain.explorers.length > 0 &&
              chain.explorers[0].url
                ? chain.explorers[0].url
                : chain.infoURL,
            ],
          };
          if (window.ethereum) {
            window.ethereum
              .request({
                method: "wallet_addEthereumChain",
                params: [params],
              })
              .then(() => {
                toast.success("Network added");
                return true;
              })
              .catch((error) => {
                toast.error(error?.message ?? "Network couldn't be added");
                return false;
              });
          }
        } else {
          toast.error(e.message);
          return false;
        }
      }
      return false;
    },
    [dispatch, metamaskAddress],
  );
};
