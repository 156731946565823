import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const customLinkStyles = {
  link: (theme: Theme): SystemStyleObject<Theme> => ({
    cursor: "pointer",
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: theme.palette.primary.main,
    },
  }),
  noDecoration: (): SystemStyleObject<Theme> => ({
    textDecoration: "none",
  }),
  noDecorationOnHover: (): SystemStyleObject<Theme> => ({
    "&:hover": {
      textDecoration: "none",
    },
  }),
  noMarginRight: (): SystemStyleObject<Theme> => ({
    marginRight: "0 !important",
  }),
  primary: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.primary.main,
  }),
  secondary: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.secondary.main,
  }),
  gray: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.grey[300],
  }),
  small: (): SystemStyleObject<Theme> => ({
    fontSize: 12,
  }),
  medium: (): SystemStyleObject<Theme> => ({
    fontSize: 14,
  }),
  large: (): SystemStyleObject<Theme> => ({
    fontSize: 16,
  }),
};

export default customLinkStyles;
