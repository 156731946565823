export const API_ROUTES = {
  // AUTH
  AUTH_ON_CHAIN_SIGN_IN: "/api/auth/sign-in/on-chain",
  AUTH_OFF_CHAIN_SIGN_IN: "/api/auth/sign-in/off-chain",
  AUTH_ON_CHAIN_SIGN_UP: "/api/auth/sign-up/on-chain",
  AUTH_OFF_CHAIN_SIGN_UP: "/api/auth/sign-up/off-chain",
  AUTH_REQUEST_CONNECT: "/api/auth/request-connect",
  AUTH_REQUEST_CONNECT_OFFCHAIN_RESEND:
    "/api/auth/request-connect/offchain/resend",
  AUTH_REQUEST_CONNECT_OFFCHAIN_CANCEL:
    "/api/auth/request-connect/offchain/cancel",
  AUTH_CONNECT: "/api/auth/connect",
  AUTH_CONNECT_OFFCHAIN: "/api/auth/connect/offchain",
  AUTH_LOGOUT: "/api/auth/logout",
  AUTH_ME: "/api/auth/me",
  AUTH_NONCE: "/api/auth/nonce",
  AUTH_MAGIC_LINK_GENERATE: "/api/auth/magic-link/generate",
  AUTH_MAGIC_LINK_VERIFY: "/api/auth/magic-link/verify",

  REQUEST_CHANGE_EMAIL_RESEND: "/api/auth/change-email/resend",
  REQUEST_CHANGE_EMAIL_CANCEL: "/api/auth/change-email/cancel",
  RECOVERY_PASSWORD: "/api/auth/forgot-password",
  SET_PASSWORD: "/api/auth/set-password",
  REQUEST_CONFIRMATION_TOKEN: "/api/auth/confirmation-token",
  REGISTER_ACTIVATION: "/api/auth/activate",
  IS_USER_AVAILABLE: "/api/user/is-available",

  USERS: "/api/user",
  USERS_INFO: "/api/user/info",
  USERS_UPDATE: "/api/user/update",
  USER_GET_ENS: "/api/ens",
  STORES: "/api/stores",
  STORE_BUNDLES: "/api/store-bundles",
  STORE_BUNDLES_REQUIREMENTS: "/api/store-bundles/requirements",
  STORES_BUNDLES_BUNDLING: "/api/store-bundles/bundling",
  STORES_BUNDLES_TRANSACTION: "/api/store-bundles/fiat-transaction",
  STORES_PRODUCT_BULK_UPDATE: "/api/store-product/bulkUpdate",
  STORES_PRODUCT_BULK_DELETE: "/api/store-product/bulkDelete",
  STORES_PRODUCT_UPDATE: "/api/store-product/update",
  STORES_PRICE: "/api/stores/price",
  STORES_VALIDATE: "/api/stores/form/validate",
  STORES_VALIDATE_PREVIEW: "/api/stores/form/validate/preview",
  STORES_VALIDATE_URL: "/api/stores/form/validate/url",
  STORES_INVOICE: "/api/stores/invoice",
  STORES_INVOICE_UPSERT: "/api/stores/invoice/upsert",
  STORE_CREATE: "/api/stores/create",
  STORE_UPDATE: "/api/stores/update",
  STORE_DELETE: "/api/stores/delete",
  STORES_PAYMENT: "/api/stores/payment",
  STORES_SUMMARY: "/api/stores/summary",
  STORES_EXPORT: "/api/stores/export",
  STORES_FREE_PAYMENT: "/api/stores/free-payment",

  STORES_DRAFTS: "/api/stores/form",
  // STORE BUNDLES FORM
  STORES_FORM_BUNDLES: "/api/store-bundles/form",
  STORES_FORM_BUNDLE_CREATE: "/api/store-bundles/form/create",
  STORES_FORM_BUNDLE_UPDATE: "/api/store-bundles/form/update",
  STORES_FORM_BUNDLE_CREATE_PHYSICAL: "/api/store-bundles/form/create-physical",
  STORES_FORM_BUNDLE_UPDATE_PHYSICAL: "/api/store-bundles/form/update-physical",
  STORES_FORM_BUNDLE_DELETE: "/api/store-bundles/form/delete",
  STORES_FORM_HASH_OVERWRITE: "/api/store-bundles/form/overwrite",
  // STORE REQUIREMENTS
  STORES_REQUIREMENT: "/api/store-requirement",
  STORES_REQUIREMENT_CREATE: "/api/store-requirement/create",
  STORES_REQUIREMENT_UPDATE: "/api/store-requirement/update",
  STORES_REQUIREMENT_DELETE: "/api/store-requirement/delete",
  STORES_REQUIREMENT_STORE: "/api/store-requirement/store",

  CONTRACT_CREATE: "/api/contracts/create",
  MARKETPLACE_LIST: "/api/marketplace",
  ETHERSCAN_ETH_PRICE: "/api/eth/price",
  ORDER_PRICE: "/api/order/price",
  ORDER_PAY: "/api/order/pay",
  REDEEM_PAY: "/api/redeem/pay",
  REDDEM_MINT: "/api/redeem/mint",
  NFTS: "/api/nfts",
  NFTS_TRANSFER: "/api/nfts/transfer",
  NFTS_COLLECTIONS: "/api/nfts/collections",
  NFTS_METADATA_REFRESH: "/api/nfts/metadata-refresh",
  TOKEN_PRICE: "/api/price",
  REQUEST: "/api/request/create",
  NFC_ITEMS: "/api/nfts/nfc",
  REDEEM_INFO: "/api/redeem/info",
  LAST_REDEEMS: "/api/redeem/last",
  NFC_MINT_OFFCHAIN: "/api/physicals/nfc/offchain",
  NFC_MINT_ONCHAIN: "/api/physicals/nfc/onchain",
  CARDS_MINT: "/api/physicals/card",
  PAYMENT_TOKENS_BY_ID: "/api/payment-token/chain",
  PAYMENT_TOKENS_BY_STORE_ID: "/api/payment-token/store",
  CONTRACTS: "/api/contracts",
  COLLECTIONS_METADATA_POLYGON: "/api/metadata-polygon/nft",
  CHANGE_OFFCHAIN_PASSWORD: "/api/user/change-password",
  FIAT_TRANSACTION: "/api/ari10/transaction",
  CONTACT: "/api/contact",
  REDEEM_PUNKS: "/api/redeem/punks",
  LOGGER: "/api/logger",
  // POAP_USER
  POAP_USER: "/api/poap/user",
  POAP_USER_CREATE: "/api/poap/user/create",
  POAP_USER_IMPORT: "/api/poap/user/import",
  POAP_USER_ITEM: "/api/poap/user/item",
  POAP_USER_RESTORE: "/api/poap/user/restore",
  // POAP_DISPENSER
  POAP_DISPENSER: "/api/poap/dispenser",
  POAP_DISPENSER_CREATE: "/api/poap/dispenser/create",
  POAP_DISPENSER_IMPORT: "/api/poap/dispenser/import",
  POAP_DISPENSER_ITEM: "/api/poap/dispenser/item",
  POAP_DISPENSER_RESTORE: "/api/poap/dispenser/restore",
  // POAP GLOBAL
  POAP_CLAIM: "/api/poap/claim",
  POAP_RESERVE: "/api/poap/reserve",
  //CART
  CART: "/api/cart",
  CART_PAYMENT: "/api/cart/payment",
  CART_CHECKOUT: "/api/cart/checkout",
  CART_FIAT_CHECKOUT: "/api/cart/fiat-checkout",
  CANCEL_TRANSACTION: "/api/transaction/cancel",
  // USER_EXTENSIONS
  USER_EXTENSIONS: "/api/user-extensions",
  USER_EXTENSIONS_ITEM: "/api/user-extensions/item",
  USER_EXTENSIONS_UPDATE: "/api/user-extensions/update",
  // DISPENSER
  DISPENSER: "/api/dispenser",
  DISPENSER_MAGIC_LINK: "/api/dispenser/magic-link",
  DISPENSER_UPDATE: "/api/dispenser/update",
  DISPENSER_UPDATE_POAP_CONFIG: "/api/dispenser/update-poap-config",
  // CAMPAIGNS
  CHRIS_LOST_HIS_ITEMS: "/api/campaigns/chris-lost-his-items/add",
  CHRIS_LOST_HIS_ITEMS_COUNT: "/api/campaigns/chris-lost-his-items/count",
  TAPPING_NFCS: "/api/campaigns/tapping-nfcs/add",
  TAPPING_NFCS_COUNT: "/api/campaigns/tapping-nfcs/count",
  FAROKH_LOST_HIS_ITEMS: "/api/campaigns/farokh-lost-his-items/add",
  COLLECT_ADDRESSES: "/api/campaigns/collect-addresses/add",
  SUBMIT_EMAIL: "/api/campaigns/submit-email/add",
  AIRDROP: "/api/airdrop",
  SOL_AIRDROP: "/api/airdrop/solana",
  TREASURE_HUNT_TASK_COMPLETE: "/api/treasure-hunt/complete",
  TREASURE_HUNT_SET_LANGUAGE: "/api/treasure-hunt/language",
  TRESURE_HUNT_CLAIM_NFT_REWARD: "/api/treasure-hunt/nft-reward",
  TREASURE_HUNT_REWARD_TRANSFER: "/api/treasure-hunt/transfer-nft-reward",
  TREASURE_HUNT_ADD_CONSENTS: "/api/treasure-hunt/add-consents",
};

export const APP_ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  TEAM: "/team",
  SIGN_IN: "/sign-in",
  VESTING: "/vesting",
  STAKING: "/staking",
  MY_ITEMS: "/my-items",
  ITEMS: "/items",
  ITEMS_NFT: "/items/nft",
  DISPENSER: "/dispenser",
  DISPENSER_DETAILS: "/dispenser-details",
  DISPENSER_VERIFICATION: "/dispenser/verification",
  GENESIS: "/genesis",
  REDEEM: "/redeem",
  LAUNCHPAD_RARITY: "/genesis/rarity",
  STORES: "/stores",

  STUDIO_CREATE: "/studio/create",
  STUDIO_CREATE_PHYSICAL: "/studio/create?physical",
  STUDIO_UPDATE: "/studio/update",
  STUDIO_LINKS: "/studio/links",

  STORE_PREVIEW: "/stores/preview",

  STORE_PRODUCT: "/stores/product",
  STORE_PAYMENT_STATUS: "/stores/payment-status",
  STORE_PRODUCT_PREVIEW: "/stores/product-preview",
  CAREERS: "/careers",
  TERMS: "/terms",
  TERMS_NFT: "/terms-NFT",
  WHITEPAPER: "/whitepaper",
  PRIVACY: "/privacy",
  UNIQLY_AUDIT: "/UNIQLY_AUDIT.pdf",
  MARKETPLACE: "/marketplace",
  USER_PROFILE: "/user-profile",
  UNIQ_TOKEN: "/uniq-token",
  INSTALL_METAMASK: "/install-metamask",
  WHITEPAPER_PDF: "/uniqly-whitepaper.pdf",
  FAQ: "/faq",
  STAKING_LIQUIDITY_POOL: "/staking-liquidity-pool",
  UNIQLY_VALENTINE_RULES: "/uniqly-valentine-rules",
  GUIDEBOOK: "/guidebook",
  INFO: "/info",
  PASSWORD_RECOVERY: "/password/recovery",
  PASSWORD_RESET: "/password/reset",
  SET_PASSWORD: "/activation/set-password",
  UNSUBSCRIBE: "/unsubscribe",
  REQUEST_CONNECT_OFFCHAIN: "/connect/offchain",
  CART: "/cart",
  ORDER: "/order",
  ORDER_PAYMENT_STATUS: "/order/payment-status",
  NFC_TAGS: "/nfc-tags",
  CHANGE_EMAIL: "/email/change",
  ACTIVATION_STATUS: "/activation/",

  TREASURE_HUNT_BASE: "/treasure-hunt",
  TREASURE_HUNT_TASK_BASE: "/treasure-hunt/task",
  TREASURE_HUNT_TRANSFER_PAGE: "/treasure-hunt/transfer-reward",
};

export const EXTERNAL_ROUTES = {
  UNIQLY_MEDIUM: "https://uniqly.medium.com/",
  UNIQLY_TELEGRAM: "https://t.me/Uniqly_io",
  UNIQLY_TWITTER: "https://twitter.com/Uniqly_io",
  UNIQLY_DISCORD: "https://discord.com/invite/CCdESMKAVU",
  UNIQLY_LINKEDIN: "https://www.linkedin.com/company/uniqly-io",
  UNIQLY_INSTAGRAM: "https://www.instagram.com/uniqly.io/",
  UNIQLY_FACEBOOK: "https://www.facebook.com/uniqly.io",
  UNIQLY_SWAP: "https://swap.uniqly.io/",
  UNIQ_DEXTOOLS:
    "https://www.dextools.io/app/uniswap/pair-explorer/0xc447aaa230af55b2eaa75227521768914ec590aa",
  UNIQ_ETHERSCAN:
    "https://etherscan.io/address/0x3758e00b100876c854636ef8db61988931bb8025",
  UNIQ_UNISWAP:
    "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3758e00b100876c854636ef8db61988931bb8025",
  UNIQ_LIQUDITY:
    "https://app.uniswap.org/#/add/v2/0x3758e00b100876c854636ef8db61988931bb8025/ETH?chain=mainnet",
  UNIQLY_VAULT: "https://opensea.io/Uniqly_io",
  UNIQLY_YT_VIDEO_THIRTS: "https://www.youtube.com/embed/MvkbYX9UvMM",
  UNIQLY_YT_VIDEO_CLAIMING: "https://www.youtube.com/embed/xXI1JKLrV4U",
  POLYGON_BRIDGE:
    "https://uniqly.medium.com/uniq-bridge-to-polygon-guide-456b4e2b35e2",
  POLYGON_BRIDGE_MEDIUM:
    "https://uniqly.medium.com/uniq-bridge-to-polygon-guide-456b4e2b35e2",

  OPENSEA_UNIQLY_ASSET: "https://opensea.io/assets/",
  OPENSEA_UNIQLY_ASSET_POLYGON: "https://opensea.io/assets/matic/",
  OPENSEA_UNIQLY_COLLECTIONS:
    "https://opensea.io/collection/uniqly-collections",
  OPENSEA_UNIQLY_COLLECTIONS_POLYGON:
    "https://opensea.io/collection/uniqly-collections-polygon",
  OPENSEA_HP: "https://opensea.io/",
  OPENSEA_UNIQLY_GENESIS: "https://opensea.io/collection/uniqly-genesis",

  ETHERSCAN_MAINNET_API_URL: "https://api.etherscan.io/api",
  ETHERSCAN_SEPOLIA_API_URL: "https://api-sepolia.etherscan.io/api",
  POLYGONSCAN_MAINNET_API_URL: "https://api.polygonscan.com/api",
  POLYGONSCAN_MUMBAI_API_URL: "https://api-testnet.polygonscan.com/api",
  BSCSCAN_MAINNET_API_URL: "https://api.bscscan.com/api",
  BSCSCAN_TESTNET_API_URL: "https://api-testnet.bscscan.com/api",
  COINGECKO_PRICE: "https://pro-api.coingecko.com/api/v3/simple/price",
  COINGECKO_MARKET: "https://pro-api.coingecko.com/api/v3/coins/markets",
  FREE_CARD_FORM_URL:
    "https://docs.google.com/forms/d/e/1FAIpQLScHO8r65xNb5OosVFrZgdw94OsD28llLmc1yqmd_6G92FXphg/viewform?embedded=true",
  MIAMI_CONF_FORM_URL:
    "https://docs.google.com/forms/d/e/1FAIpQLSc8pZ6C8su8cBI-hKcjhz5k4Y0g2jf5-XdYptQAt4wcuAKH-A/viewform?usp=sf_link?embedded=true",
  QUICKSWAP:
    "https://quickswap.exchange/#/swap?inputCurrency=MATIC&outputCurrency=0xabeb34c52292c5f3ee96396e02d8a77c8a903a4f",
  DECENTRALAND:
    "https://play.decentraland.org/?NETWORK=mainnet&island=I3oi&position=-63%2C-102&realm=dg",
  DECENTRALAND_HP: "https://decentraland.org/",
  WEARABLES_3_0: process.env.NEXT_PUBLIC_WEARABLES_URL,
  WEARABLES_3_0_METADATA: `${process.env.NEXT_PUBLIC_WEARABLES_URL}/metadata/`,
  WEARABLES_3_0_GENERATOR: `${process.env.NEXT_PUBLIC_WEARABLES_URL}/generator/`,
  WEARABLES_3_0_PREVIEW: `${process.env.NEXT_PUBLIC_WEARABLES_URL}/preview/`,
  DECENTRALAND_CONTRACTS: "https://market.decentraland.org/contracts/",
  GENESIS_RARITY_TOOLS: "https://rarity.tools/uniqly-genesis",
  UNIQ_COINGECKO: "https://www.coingecko.com/pl/waluty/uniqly",
  UNIQ_POLYGONSCAN:
    "https://polygonscan.com/token/0xabeb34c52292c5f3ee96396e02d8a77c8a903a4f",
  UNIQ_DEXTOOLS_POLYGON:
    "https://www.dextools.io/app/polygon/pair-explorer/0x3f30fdf2bc38308fc7a4523c6da402ce89f16e02",
  DISCOVER_UNIQLY_VIDEO:
    "https://player.vimeo.com/video/687047391?h=55795a0882&title=0&byline=0&portrait=0",
  DISCOVER_UNIQLY_DOC:
    "https://docs.google.com/forms/d/e/1FAIpQLSfSXaSyy7GqjPhbnYvrSPbIZ-5fkomYltBmlb3VbzLeYnztug/viewform?embedded=true",
  STORE_ASSETS_AVATAR:
    "https://drive.google.com/file/d/1s96zOjl3oXgL6_0VffmPG9tmavQv_5uU/view",
  STORE_ASSETS_STORE_HEADER:
    "https://drive.google.com/file/d/1X84D9RBjb_I7i-TcTD68JnV4keN89J2D/view",
  STORE_ASSETS_STORE_BACKGROUND:
    "https://drive.google.com/file/d/1pNWhHzLDFNx5nmOxX-XYrnhV82qzd73h/view",
  METAMASK: "https://metamask.io",
  POAP_TESTING:
    "https://poap.zendesk.com/hc/en-us/articles/16269831538957-How-to-create-test-drops",
  POAP_TERMS: "https://poap.xyz/terms-of-service",
  POAP_CLAIM: "https://app.poap.xyz/claim",
  POAP_ABOUT: "https://poap.xyz/about-the-protocol",
  POAP_POLICY:
    "https://curation.poap.xyz/guidelines/policy-and-process/commercial-use-policy",
  RECAPTCHA_TERMS: "https://policies.google.com/terms",
  RECAPTCHA_PRIVACY: "https://policies.google.com/privacy",
  POAP_SETUP:
    "https://docs.google.com/document/d/1DnsAxIVkAz5dGkg7hKMTlv5gVUhngl_ImGwBWZP_gWc/edit?usp=sharing",
};
