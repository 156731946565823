import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PaymentToken } from "@prisma/client";

import { RootState } from "redux/store";

import { CHAIN_IDS } from "enums/chainIds";

import { API_ROUTES } from "config/routes";

import fetcher from "lib/fetchJson";

export const fetchTokens = createAsyncThunk<PaymentToken[] | [], CHAIN_IDS>(
  "tokens/fetchTokensByChainId",
  async (chainId) => {
    try {
      const response = await fetcher(
        `${API_ROUTES.PAYMENT_TOKENS_BY_ID}/${chainId}`,
      );
      return response.data || [];
    } catch {
      return [];
    }
  },
);

type SliceState = {
  tokens: PaymentToken[];
};

const initialState: SliceState = {
  tokens: [],
};

const tokensSlices = createSlice({
  name: "tokens",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTokens.fulfilled, (state, action) => {
      state.tokens = action.payload;
    });
  },
});

export const selectTokens = (state: RootState) => state.tokens.tokens;

export default tokensSlices.reducer;
