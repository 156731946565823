import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "redux/store";

import { generateNonce } from "utils/data";

type SliceState = {
  hash: string;
};

const initialState: SliceState = {
  hash: "",
};

const loggerSlices = createSlice({
  name: "logger",
  initialState,
  reducers: {
    generateHash: (state) => {
      state.hash = generateNonce();
    },
  },
});

export const selectHash = (state: RootState) => state.logger.hash;

export const { generateHash } = loggerSlices.actions;

export default loggerSlices.reducer;
