export const ETHER_MAX =
  "0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF";

export const ETHER_ADDRESS_O = "0x0000000000000000000000000000000000000000";
export const ETHER_DECIMAL = 18;

export const UNIQLY_WALLET_ADDRESS =
  "0x2B1cBCd0b1a9E8bcC82e86dD3FD5313d4692AaDE";

export const NYC_TX_ADDRESS =
  "0xca5fea20e038716f57e67af5cee3a4f8502ad0e9d6b214a50f0eeb4dc45756c4";
