import { useRouter } from "next/router";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";

import { useAccount, useBalance, useNetwork } from "wagmi";
import { utils } from "ethers";

import { usePaymentTokenAddress } from "./usePaymentTokenAddress";

import store, { useAppDispatch } from "redux/store";
import {
  clearUser,
  fetchLoggedInUser,
  selectAddress,
  selectChainId,
  selectSignInType,
  setChainId,
  setCurrentNetworkBalance,
  setThemeMode,
  setUniqBalance,
} from "redux/slices/metamask";
import { fetchTokens } from "redux/slices/tokens";

import { PAYMENT_TOKENS, POLYGON_TOKENS } from "enums/paymentTokens";
import { STORAGE_THEME_NAME, THEME_MODES } from "enums/themeModes";
import { SIGN_IN_TYPE } from "enums/signInTypes";

import { STORAGE_CHAIN_ID_NAME } from "config/networks";
import { API_ROUTES } from "config/routes";

import { captureException } from "utils/errors";
import { init } from "utils/ga";
import {
  getEthereumChainId,
  isAddressesEquals,
  isChainIdSupported,
  isPolygonNetwork,
} from "utils/blockchain";
import { getItem } from "utils/storage";

import fetcher from "lib/fetchJson";

const useAppInit = (): void => {
  const dispatch = useAppDispatch();
  const { chain } = useNetwork();
  const { address: connectedWalletAddress, isConnected } = useAccount();
  const queryClient = useQueryClient();
  const chainId = useSelector(selectChainId);
  const walletAddress = useSelector(selectAddress);
  const signInType = useSelector(selectSignInType);
  const tokenAddress = usePaymentTokenAddress(
    chain?.id && isPolygonNetwork(chain?.id)
      ? POLYGON_TOKENS.UNIQ_POLYGON
      : PAYMENT_TOKENS.UNIQ,
  );
  const { data } = useBalance({
    address: walletAddress as `0x${string}`,
    enabled: Boolean(walletAddress) && Boolean(chain?.id),
    chainId: chain?.id,
    formatUnits: "wei",
  });
  const { data: uniqBalance } = useBalance({
    address: walletAddress as `0x${string}`,
    token: tokenAddress as `0x${string}`,
    enabled:
      Boolean(walletAddress) && Boolean(tokenAddress) && Boolean(chain?.id),
    chainId: chain?.id,
    formatUnits: "wei",
  });

  useEffect(() => {
    store.dispatch(fetchLoggedInUser());
  }, []);

  // Logout user if wallet address changed in Metamask and user is logged in ONCHAIN
  useEffect(() => {
    if (signInType !== SIGN_IN_TYPE.ONCHAIN) {
      return;
    }
    const checkUser = async () => {
      try {
        if (
          (walletAddress &&
            connectedWalletAddress &&
            !isAddressesEquals(walletAddress, connectedWalletAddress)) ||
          (walletAddress && !isConnected && !connectedWalletAddress)
        ) {
          await fetcher(API_ROUTES.AUTH_LOGOUT);
          dispatch(clearUser());
          await queryClient.invalidateQueries();
        }
      } catch (error) {
        captureException(error);
      }
    };
    checkUser();
  }, [
    queryClient,
    isConnected,
    walletAddress,
    connectedWalletAddress,
    dispatch,
    signInType,
  ]);

  useEffect(() => {
    init(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS);

    store.dispatch(
      setThemeMode(
        (getItem(STORAGE_THEME_NAME) as THEME_MODES) || THEME_MODES.DARK,
      ),
    );
  }, []);

  useEffect(() => {
    chainId && dispatch(fetchTokens(chainId));
  }, [dispatch, chainId]);

  useEffect(() => {
    if (uniqBalance?.formatted && chain?.id && isChainIdSupported(chain?.id)) {
      dispatch(setUniqBalance(uniqBalance?.formatted ?? "0"));
    }
  }, [dispatch, chain?.id, uniqBalance?.formatted]);

  useEffect(() => {
    if (data?.formatted && chain?.id && isChainIdSupported(chain?.id)) {
      dispatch(setCurrentNetworkBalance(data?.formatted));
    }
  }, [dispatch, chain?.id, data?.formatted]);

  useEffect(() => {
    if (!walletAddress && chain?.id && !isChainIdSupported(chain?.id)) {
      dispatch(setChainId(getEthereumChainId()));
      return;
    }
    const storageChainId = getItem(STORAGE_CHAIN_ID_NAME);

    if (!walletAddress && isChainIdSupported(parseInt(storageChainId))) {
      dispatch(setChainId(parseInt(storageChainId)));
      return;
    }

    chain?.id && dispatch(setChainId(chain?.id));
  }, [dispatch, walletAddress, chain?.id]);

  useEffect(() => {
    if (!window.ethereum) {
      return;
    }
    const isLoggedIn = walletAddress ? utils.isAddress(walletAddress) : false;

    const handleChainIdChanged = (chainId) => {
      isLoggedIn && dispatch(setChainId(parseInt(chainId, 16)));
    };

    const handleAccountsChanged = async (accounts) => {
      if (signInType !== SIGN_IN_TYPE.ONCHAIN) {
        return;
      }
      if (isLoggedIn || !accounts.length) {
        await fetcher(API_ROUTES.AUTH_LOGOUT);
        dispatch(clearUser());
        await queryClient.invalidateQueries();
      }
    };

    window.ethereum?.on?.("chainChanged", handleChainIdChanged);
    window.ethereum?.on?.("accountsChanged", handleAccountsChanged);

    return () => {
      window.ethereum?.removeListener?.("chainChanged", handleChainIdChanged);
      window.ethereum?.removeListener?.(
        "accountsChanged",
        handleAccountsChanged,
      );
    };
  }, [dispatch, signInType, walletAddress, queryClient]);

  const router = useRouter();

  // Transfer embedded query param
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const { embedded } = router.query;
      if (!embedded) {
        return;
      }

      if (!url.startsWith("/")) {
        return;
      }

      const [pathname, rawParams] = url.split("?");
      const params = new URLSearchParams(rawParams);

      if (params.has("embedded")) {
        return;
      }

      params.set("embedded", String(embedded));

      router.replace(`${pathname}?${params}`);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);
};

export default useAppInit;
