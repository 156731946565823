import { FC } from "react";

import CustomButton, { CustomButtonProps } from "components/CustomButton";

import { useChangeNetwork } from "hooks/useChangeNetwork";

import { getChainById } from "utils/blockchain";

type ChangeNetworkButtonProps = {
  chainId: number;
  buttonProps?: CustomButtonProps;
  onChange?: () => void | null;
};

const ChangeNetworkButton: FC<ChangeNetworkButtonProps> = ({
  chainId,
  buttonProps,
  onChange,
}) => {
  const handleChangeNetwork = useChangeNetwork();
  const chain = getChainById(chainId);

  return chain ? (
    <CustomButton
      fullWidth
      color="primary"
      onClick={async () => {
        const result = await handleChangeNetwork(chainId);
        if (result && onChange) {
          onChange();
        }
      }}
      {...buttonProps}
    >
      Switch to {chain.chain}
    </CustomButton>
  ) : null;
};
export default ChangeNetworkButton;
