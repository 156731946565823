import { useRouter } from "next/router";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Image from "next/legacy/image";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { selectAddress, selectChainId } from "redux/slices/metamask";

import Modal from "components/Modal";
import ChangeNetworkButton from "components/ChangeNetworkButton";

import { getSupportedChainIds, isChainIdSupported } from "utils/blockchain";

const supportedChainIds = getSupportedChainIds();

const WrongNetworkModal = () => {
  const { asPath } = useRouter();
  const address = useSelector(selectAddress);
  const chainId = useSelector(selectChainId);

  const [isOpen, setWrongNetworkModal] = useState(false);

  useEffect(() => {
    if (
      !asPath ||
      !chainId ||
      address === null ||
      isChainIdSupported(chainId)
    ) {
      setWrongNetworkModal(false);
      return;
    }

    setWrongNetworkModal(true);
  }, [address, chainId, asPath]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={() => ({})}
        title="WRONG NETWORK"
        hideCloseButton={true}
        darkBackground={true}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="330px"
        >
          <Typography align="center">Change network in your wallet.</Typography>
          <Box py={3}>
            <Image
              src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/common/wrong-network.png`}
              width="150"
              height="150"
              alt="wrong-network"
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            {supportedChainIds.map((chainId) => (
              <ChangeNetworkButton
                buttonProps={{ fullWidth: false, sx: { minWidth: 220 } }}
                key={`chainId-${chainId}`}
                chainId={chainId}
                onChange={() => setWrongNetworkModal(false)}
              />
            ))}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default WrongNetworkModal;
