import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const customToastContainerStyles = {
  container: (theme: Theme): SystemStyleObject<Theme> => ({
    ".Toastify__toast-container": {
      fontSize: 14,
      zIndex: 99999,
    },
    ".Toastify__toast-theme--light": {
      color: theme.palette.primary.main,
    },
    ".Toastify__toast": {
      borderRadius: 0,
    },
    ".Toastify__toast--success": {
      backgroundColor: theme.palette.success.main,
    },
    ".Toastify__toast--error": {
      backgroundColor: theme.palette.error.main,
    },
    ".Toastify__toast--warning": {
      backgroundColor: theme.palette.warning.main,
    },
    ".Toastify__toast--info": {
      backgroundColor: theme.palette.info.light,
    },
  }),
};

export default customToastContainerStyles;
