import { Theme } from "@mui/material/styles";
import { alpha, SystemStyleObject } from "@mui/system";

export const boxShadow = (color: string): string =>
  `0 2px 2px 0 ${alpha(color, 0.14)}, 0 3px 1px -2px ${alpha(
    color,
    0.2,
  )}, 0 1px 5px 0 ${alpha(color, 0.12)}`;

export const boxShadowHover = (color: string): string => {
  return `0 14px 26px -12px ${alpha(color, 0.42)}, 0 4px 23px 0px ${alpha(
    color,
    0.12,
  )}, 0 8px 10px -5px ${alpha(color, 0.2)}`;
};

const customButtonStyles = {
  button: (theme: Theme): SystemStyleObject<Theme> => ({
    display: "inline-flex",
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.secondary,
    boxShadow: boxShadow(theme.palette.grey[300]),
    border: "none",
    position: "relative",
    px: 3,
    py: 1,
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition: theme.transitions.create(["box-shadow", "background-color"]),
    lineHeight: "19px",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    borderRadius: 0,
    "& span": {
      fontWeight: 600,
    },
    "&:hover,&:focus": {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.grey[300],
      boxShadow: `0 14px 26px -12px ${alpha(
        theme.palette.grey[300],
        0.42,
      )}, 0 4px 23px 0px ${alpha(
        theme.palette.text.primary,
        0.12,
      )}, 0 8px 10px -5px ${alpha(theme.palette.grey[300], 0.2)}`,
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "& > svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      verticalAlign: "middle",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  fullWidth: {
    width: "100%",
  },
  primary: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.background.default,
    backgroundColor: theme.palette.text.primary,
    boxShadow: boxShadow(theme.palette.text.primary),
    border: `1px solid ${theme.palette.text.primary}`,
    "&:hover,&:focus-visible": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      boxShadow: boxShadowHover(theme.palette.text.primary),
      border: `1px solid ${theme.palette.text.primary}`,
    },
    "&:active": {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.secondary.light}`,
    },
  }),
  secondary: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    boxShadow: boxShadow(theme.palette.text.primary),
    border: `1px solid ${theme.palette.text.primary}`,
    "&:hover,&:focus-visible": {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.text.primary,
      boxShadow: boxShadowHover(theme.palette.text.primary),
      border: `1px solid ${theme.palette.text.primary}`,
    },
    "&:active": {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.secondary.light}`,
    },
  }),
  info: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.info[500],
    boxShadow: boxShadow(theme.palette.info[500]),
    "&:hover,&:focus-visible": {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.info[500],
      boxShadow: boxShadowHover(theme.palette.info[500]),
    },
  }),
  success: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.success.main,
    boxShadow: boxShadow(theme.palette.success.main),
    "&:hover,&:focus-visible": {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.success.main,
      boxShadow: boxShadowHover(theme.palette.success.main),
    },
  }),
  warning: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    background: `linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.dark} 100%)`,
    boxShadow: boxShadow(theme.palette.secondary.main),
    "&:hover,&:focus-visible": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.warning.main,
      boxShadow: boxShadowHover(theme.palette.secondary.main),
    },
  }),
  danger: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.error.main,
    boxShadow: boxShadow(theme.palette.error.main),
    "&:hover,&:focus-visible": {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.error.main,
      boxShadow: boxShadowHover(theme.palette.error.main),
    },
  }),
  white: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.grey[300],
    boxShadow: boxShadow(theme.palette.grey[300]),
    "&:hover,&:focus-visible": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.grey[300],
      boxShadow: boxShadowHover(theme.palette.grey[300]),
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.secondary.light}`,
    },
  }),
  simple: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.text.secondary,
    background: "transparent",
    boxShadow: "none",
    border: "none",
    "&:hover,&:focus-visible": {
      color: theme.palette.text.secondary,
      background: "transparent",
      boxShadow: "none",
      border: "none",
    },
    "&:active,&:focus": {
      background: "transparent",
      border: "none",
      boxShadow: "none",
    },
  }),
  transparent: {
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none",
    },
  },
  disabled: {
    opacity: "0.5",
    pointerEvents: "none",
  },
  lg: {
    padding: "1.125rem 2.25rem",
    fontSize: "0.875rem",
    lineHeight: "1.333333",
  },
  sm: {
    padding: "0.40625rem 1.25rem",
    fontSize: "0.6875rem",
    lineHeight: "1.5",
  },
  round: {
    borderRadius: 1,
  },
  block: {
    width: "100% !important",
  },
  link: (theme: Theme) =>
    ({
      border: "none",
      "&,&:hover,&:focus": {
        backgroundColor: "transparent",
        color: theme.palette.grey[500],
        boxShadow: "none",
        border: "none",
      },
    }) as const,
  justicon: {
    paddingLeft: "12px",
    paddingRight: "12px",
    fontSize: "20px",
    height: "41px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px",
      },
      "& svg": {
        width: "32px",
        height: "32px",
      },
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px",
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
  },
  minWidth: {
    minWidth: "160px",
    maxWidth: "160px",
  },
};

export default customButtonStyles;
