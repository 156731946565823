import { FC } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

type LoaderProps = {
  size?: number;
  label?: string | undefined | null;
  center?: boolean;
  white?: boolean;
  inherit?: boolean;
};
const Loader: FC<LoaderProps> = ({
  size = 60,
  label,
  center = true,
  white = false,
  inherit = false,
}) => {
  return (
    <Box
      display="flex"
      justifyContent={center ? "center" : "left"}
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Box mr={1} display="flex" alignItems="center">
        <CircularProgress
          disableShrink
          size={size}
          /* eslint-disable-next-line */
          // @ts-ignore
          color={inherit ? "inherit" : white ? "text" : "primary"}
        />
      </Box>
      {label && (
        <Typography variant="body2" color={inherit ? "inherit" : "textPrimary"}>
          <b>{label}</b>
        </Typography>
      )}
    </Box>
  );
};

export default Loader;
