import { FC } from "react";
import { ToastContainer } from "react-toastify";

import Box from "@mui/material/Box";

import styles from "./styles";

const CustomToastContainer: FC = () => {
  return (
    <Box sx={styles.container}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        draggable={false}
        icon={false}
      />
    </Box>
  );
};

export default CustomToastContainer;
